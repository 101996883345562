import Carousel from 'react-multi-carousel';

interface IPromoCardProps {
  title: string;
  image: string;
  link: string;
  isVideo?: boolean;
}

function PromoCard(props: IPromoCardProps) {
  return (
    <div className="px-2">
      <a href={props.link} className="text-black hover:text-ow-secondary hover:no-underline">
        <div className="bg-black h-[210px] md:h-[180px] bg-cover bg-center" style={{ backgroundImage: `url(/images/${props.image})` }}>
          {props.isVideo && <img src="/icons/play.svg" alt="Play icon" className="h-12 w-12" />}
        </div>
        <div className="bg-white min-h-28 p-5 pr-12 relative">
          <h4 className="font-mmc leading-6">{props.title}</h4>
          <img src="/icons/chevron-right.svg" alt="Link icon" className="absolute bottom-5 right-5" />
        </div>
      </a>
    </div>
  );
}

const promoConfig: IPromoCardProps[] = [
  {
    title: 'Navigating the Future: Responsible Stewardship of AI in Financial Services',
    image: 'navigating-the-future.png',
    link: 'https://vimeo.com/903837144/74802294cf',
    isVideo: true,
  },
  {
    title: 'Factiva Sentiment Signals wins Credit Risk Innovation of the Year',
    image: 'credit-risk-innovation.png',
    link: 'https://www.dowjones.com/professional/resources/blog/factiva-sentiment-signals-wins-award',
  },
  {
    title: 'Dow Jones Launches Factiva Sentiment Signals Powered by Oliver Wyman',
    image: 'ow-social.png',
    link: 'https://www.oliverwyman.com/media-center/2024/jan/dow-jones-launches-factiva-sentiment-signals-powered-by-oliver-wyman.html',
  },
  {
    title: 'We have left the explanatory stage in AI',
    image: 'explanatory-stage-in-ai.png',
    link: 'https://www.boersen-zeitung.de/english/explanatory-ai',
  },
  {
    title: 'AI is increasingly able to help us like a crystal ball looking in to the future',
    image: 'crystal-ball.png',
    link: 'https://www.cnbc.com/video/2024/01/15/private-credit-providers-are-experiencing-a-good-moment-says-huw-van-steenis.html',
    isVideo: true,
  },
  {
    title: 'Factiva Sentiment Signals Powered by Oliver Wyman',
    image: 'factiva-insights.png',
    link: 'https://www.dowjones.com/professional/factiva/factiva-sentiment-signals/',
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1536 },
    items: 4,
  },
  smDesktop: {
    breakpoint: { max: 1535, min: 1280 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1279, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

export default function Promo() {
  return (
    <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000}>
      {promoConfig.map((promo, index) => (
        <PromoCard key={index} {...promo} />
      ))}
    </Carousel>
  );
}
