import { useLocation } from 'react-router-dom';
import { IGroupedArticles } from '../types/article';
import { useRef, useState } from 'react';
import ArticleItem from './ArticleItem';

export default function GroupedArticle({
  groupedArticle,
  companyId = '',
  companyName = '',
  isThemeItem = false,
  i,
}: Readonly<{
  groupedArticle: IGroupedArticles;
  companyId?: string;
  companyName?: string;
  isThemeItem?: boolean;
  i: number;
}>) {
  const location = useLocation();
  const articleRef = useRef<HTMLLIElement | null>(null);
  const [showSimilar, setShowSimilar] = useState(false);

  const toggleShowSimilar = (event: any) => {
    setShowSimilar(!showSimilar);
    // normally events on a page with the CompanyGraphs component causes a scroll to the top of the page
    //   by the function handleOutsideChartClick
    // to prevent this, use:
    event.stopPropagation();
  };

  return (
    <li
      ref={i === location.state?.index ? articleRef : undefined}
      key={groupedArticle.mainArticle.dowj_article_id}
      className={(isThemeItem ? 'mb-2' : 'mb-4') + ' leading-normal text-xs'}
    >
      <ArticleItem article={groupedArticle.mainArticle} companyId={companyId} companyName={companyName} isThemeItem={isThemeItem} />
      {!showSimilar && (
        <div onClick={(event: any) => toggleShowSimilar(event)}>
          {groupedArticle.similarArticles.length > 0 && (
            <div className="ml-2 mt-2 text-ow-secondary block text-xs">
              + Show duplicate article{groupedArticle.similarArticles.length > 1 ? 's' : ''} ({groupedArticle.similarArticles.length})
            </div>
          )}
        </div>
      )}
      {showSimilar && (
        <div onClick={(event: any) => toggleShowSimilar(event)}>
          {groupedArticle.similarArticles.length > 0 && (
            <div className="ml-2 mt-2 text-ow-secondary block text-xs">
              - Hide duplicate article{groupedArticle.similarArticles.length > 1 ? 's' : ''} ({groupedArticle.similarArticles.length})
            </div>
          )}
          {groupedArticle.similarArticles.map((similarArticle, j) => (
            <div className="ml-6 mt-2" key={similarArticle.dowj_article_id}>
              <ArticleItem article={similarArticle} companyId={companyId} companyName={companyName} isThemeItem={isThemeItem} />
            </div>
          ))}
        </div>
      )}
    </li>
  );
}
