import { IGroupedArticles } from '../types/article';
import GroupedArticle from './GroupedArticle';

export default function GroupedArticles({
  groupedArticles,
  companyId = '',
  companyName = '',
  isThemeItem = false,
}: Readonly<{
  groupedArticles: IGroupedArticles[];
  companyId?: string;
  companyName?: string;
  isThemeItem?: boolean;
}>) {
  return (
    <ul>
      {groupedArticles.map((groupedArticle, i) => (
        <GroupedArticle
          key={groupedArticle.group_key}
          groupedArticle={groupedArticle}
          companyId={companyId}
          companyName={companyName}
          isThemeItem={isThemeItem}
          i={i}
        />
      ))}
    </ul>
  );
}
