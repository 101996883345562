import { atom } from 'recoil';
import IConfig from '../types/config';

export const userGroupAtom = atom({
  key: 'userGroupAtom',
  default: '',
});

export const superuserPermissionAtom = atom({
  key: 'superuserPermissionAtom',
  default: false,
});

export const configAtom = atom<IConfig | null>({
  key: 'configAtom',
  default: null,
});
