import { useEffect } from 'react';
import useCurrentPage from '../hooks/use-set-current-page';
import Container from '../components/Container';
import Lists from '../components/Lists';

export default function Home() {
  const currentPage = useCurrentPage();
  useEffect(() => {
    currentPage.setCurrentPage('Portfolio - Lists');
  }, []);

  return (
    <div className="mb-auto lg:bg-ow-lighter-grey">
      <Container>
        <div className="lg:flex lg:justify-center lg:w-full">
          <div className="lg:w-full lg:bg-white lg:p-8">
            <div>
              <Lists />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
