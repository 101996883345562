import Select from 'react-select';

export default function SelectInput({
  options,
  value,
  onChange,
  onMenuOpen,
  color,
  bgcolor = '#2C6EF2',
  minWidth = 0,
  textAlign = 'right',
  padding = 6,
  height = 30,
  marginTop = 0,
  defaultStyles = false,
}: Readonly<{
  options: any;
  value: any;
  onChange: any;
  onMenuOpen?: any;
  color?: string;
  bgcolor?: string;
  minWidth?: number;
  textAlign?: string;
  padding?: number;
  height?: number;
  marginTop?: number;
  defaultStyles?: boolean;
}>) {
  const selectStyles = defaultStyles
    ? {
        container: (provided: any) => ({
          ...provided,
          minWidth: 140,
          maxWidth: 400,
        }),
        indicatorSeparator: (provided: any) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided: any) => ({
          ...provided,
          color: '#2C6EF2', // ow-secondary,
        }),
      }
    : {
        control: (provided: any) => ({
          ...provided,
          cursor: 'pointer',
          border: 0,
          fontSize: '0.75rem',
          padding: padding,
          minHeight: 0,
          height: height,
          marginTop: marginTop,
          background: bgcolor ?? '#2C6EF2',
          borderRadius: 0,
          minWidth,
        }),
        singleValue: (provided: any) => ({
          ...provided,
          color: color ?? '#FFFFFF',
          textAlign,
        }),
        dropdownIndicator: (provided: any) => ({
          ...provided,
          paddingTop: 8,
          maxWidth: '2rem',
          color: color ?? '#FFFFFF',
        }),
        valueContainer: (provided: any) => ({
          ...provided,
          padding: 0,
        }),
        indicatorsContainer: (provided: any) => ({
          ...provided,
          maxHeight: '1rem',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        option: (provided: any, state: any) => ({
          ...provided,
          cursor: 'pointer',
          background: 'transparent',
          color: state.isSelected ? '#2C6EF2' : 'initial',
          fontSize: '0.75rem',
          padding: '0.375rem 0.375rem 0.375rem 0.375rem',
          textAlign: 'center',
        }),
      };

  return (
    <div>
      <Select
        isSearchable={false}
        styles={selectStyles}
        value={value}
        options={options}
        onChange={onChange}
        onMenuOpen={onMenuOpen}
        classNamePrefix="ignore-click"
        menuPosition="fixed"
      ></Select>
    </div>
  );
}
