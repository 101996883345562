import { useNavigate } from 'react-router-dom';

export default function BackButton() {
  const navigate = useNavigate();

  return (
    <div className="flex justify-start text-sm text-ow-light-blue">
      <button onClick={() => navigate(-1)} className="flex items-center">
        <img src="/icons/back.svg" alt="Back arrow" className="pr-1 h-3 w-4" /> <span className="hidden xl:block">Back</span>
      </button>
    </div>
  );
}
