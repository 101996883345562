import { useRecoilValue } from 'recoil';
import { headerInfoAtom } from '../store/header-info';
import { filterCountriesAtom, filterIndustriesAtom, filterListsAtom } from '../store/filters';

export default function useChartTitle() {
  const filterLists = useRecoilValue(filterListsAtom);
  const filterIndustries = useRecoilValue(filterIndustriesAtom);
  const filterCountries = useRecoilValue(filterCountriesAtom);
  const headerInfo = useRecoilValue(headerInfoAtom);
  const prefix = '&nbsp;&nbsp;';

  const _formatListTitle = () => {
    return `${prefix}LIST: ${headerInfo?.name} (${headerInfo?.numCompanies}) | AVG SCORE: ${headerInfo?.score} | AVG CHANGE: ${headerInfo?.change} | AVG SIGNAL: ${headerInfo?.signal.text}`;
  };

  const _formatPortfolioTitle = () => {
    let title = `${prefix}Portfolio`;
    const filters = [];
    const lists = filterLists.length ? `Lists: ${filterLists.join(', ')}` : '';
    const industries = filterIndustries.length ? `Industries: ${filterIndustries.join(', ')}` : '';
    const countries = filterCountries.length ? `Countries: ${filterCountries.join(', ')}` : '';

    if (lists.length) filters.push(lists);
    if (industries.length) filters.push(industries);
    if (countries.length) filters.push(countries);

    if (filters.length) {
      title += ` | ${filters.join(' | ')}`;
    }

    return title;
  };

  const formatChartTitle = (list: boolean) => {
    if (list) {
      return _formatListTitle();
    }

    return _formatPortfolioTitle();
  };

  const formatCompanyChartTitle = () => {
    return `${headerInfo?.name}, ${headerInfo?.country} | SCORE: ${headerInfo?.score} | CHANGE: ${headerInfo?.change} | SIGNAL: ${headerInfo?.signal.text}`;
  };

  return {
    formatChartTitle,
    formatCompanyChartTitle,
  };
}
