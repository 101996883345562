import { useEffect, useState } from 'react';
import { ISearchedCompany } from '../types/company';
import useApiCaller from '../hooks/use-api-caller';
import Loading from '../components/Loading';
import SearchList from '../components/SearchList';
import FSSError from '../components/FSSError';
import { addSortFieldToCompanies } from '../utils/add-sort-field-to-companies';
import { triggerSearchAtom } from '../store/search-term';
import { useRecoilState } from 'recoil';
import { dataDogAddAction } from '../utils/data-dog-logging';

export default function SearchComponent({
  searchTerm,
  bulkMode,
}: Readonly<{
  searchTerm: string;
  bulkMode: boolean;
}>) {
  const apiCaller = useApiCaller();
  const [triggerSearch] = useRecoilState(triggerSearchAtom);

  useEffect(() => {
    if (searchTerm) {
      search(searchTerm);
    }
  }, [searchTerm, triggerSearch]);

  const [searching, setSearching] = useState(false);
  const [searchedCompanies, setSearchedCompanies] = useState<ISearchedCompany[]>([]);
  const [searchError, setSearchError] = useState(false);

  const search = (searchTerm: string) => {
    if (!searchTerm) {
      return;
    }

    setSearching(true);

    apiCaller
      .searchIncludingPortfolio(searchTerm, bulkMode)
      .then((searchResponse) => {
        const scoredCompanies = searchResponse.items;

        const sortableCompanies = addSortFieldToCompanies(scoredCompanies);
        setSearchedCompanies(sortableCompanies);
        setSearching(false);

        const item_count = searchResponse.items.length;
        dataDogAddAction('search', {
          searchTerm: searchTerm,
          itemCount: item_count,
        });
      })
      .catch((err) => {
        console.error(err);
        setSearchError(true);
        setSearching(false);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  return (
    <div>
      {searching && <Loading />}
      {searchError && <FSSError />}
      {!searching && !searchError && (
        <div>
          <SearchList searchedCompanies={searchedCompanies} searchTerm={searchTerm ?? ''} bulkMode={bulkMode} />
        </div>
      )}
    </div>
  );
}
