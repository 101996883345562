import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NavLink } from 'react-router-dom';
import Loading from './Loading';
import FSSError from './FSSError';
import ListItem from './ListItem';
import { IListStatistics } from '../types/company';
import ListHeader from './ListHeader';
import { dailyWeeklyAtom } from '../store/portfolio';
import { compareNames } from '../utils/compare-functions';
import { companiesErrorAtom, loadingCompaniesAtom, userListStatsAtom } from '../store/portfolio';

export default function Lists() {
  const loadingCompanies = useRecoilValue<boolean>(loadingCompaniesAtom);
  const companiesError = useRecoilValue<boolean>(companiesErrorAtom);

  const listsStatistics = useRecoilValue<IListStatistics[]>(userListStatsAtom);

  const [sortDirection, setSortDirection] = useState<string>('UP');
  const [sortField, setSortField] = useState<string>('NAME');

  const [dailyOrWeekly, setDailyOrWeekly] = useRecoilState<string>(dailyWeeklyAtom);

  const MyListstatOrder = (listStatA: IListStatistics, listStatB: IListStatistics): number => {
    if (sortField === 'NAME') {
      return compareNames(listStatA.listName, listStatB.listName, sortDirection);
    } else if (sortField === 'DOWNGRADE') {
      const sortOrderDifference =
        sortDirection === 'DOWN'
          ? listStatA.current_score_sort_order - listStatB.current_score_sort_order
          : listStatB.current_score_sort_order - listStatA.current_score_sort_order;

      if (sortOrderDifference === 0) {
        return compareNames(listStatA.listName, listStatB.listName, 'UP');
      }
      return sortOrderDifference;
    } else if (sortField === 'SCORE') {
      const scoreDifference =
        sortDirection === 'UP' ? listStatA.current_score - listStatB.current_score : listStatB.current_score - listStatA.current_score;

      if (scoreDifference === 0) {
        return compareNames(listStatA.listName, listStatB.listName, 'UP');
      }
      return scoreDifference;
    } else if (sortField === 'PERCENT_CHANGE') {
      let scoreDifference = 0;
      if (dailyOrWeekly === 'DAILY') {
        scoreDifference =
          sortDirection === 'UP'
            ? listStatA.previous_day_percent_change - listStatB.previous_day_percent_change
            : listStatB.previous_day_percent_change - listStatA.previous_day_percent_change;
      } else {
        scoreDifference =
          sortDirection === 'UP'
            ? listStatA.previous_week_percent_change - listStatB.previous_week_percent_change
            : listStatB.previous_week_percent_change - listStatA.previous_week_percent_change;
      }
      if (scoreDifference === 0) {
        return compareNames(listStatA.listName, listStatB.listName, 'UP');
      }
      return scoreDifference;
    } else if (sortField === 'COUNT') {
      const scoreDifference =
        sortDirection === 'UP'
          ? listStatA.numberOfCompanies - listStatB.numberOfCompanies
          : listStatB.numberOfCompanies - listStatA.numberOfCompanies;

      if (scoreDifference === 0) {
        return compareNames(listStatA.listName, listStatB.listName, 'UP');
      }
      return scoreDifference;
    }
    console.log('Sort field not yet supported');
    return 0;
  };

  return (
    <div>
      {loadingCompanies && <Loading />}
      {companiesError && <FSSError />}
      {!loadingCompanies && !companiesError && listsStatistics.length !== 0 && (
        <div>
          <h2 className="hidden lg:block mb-4 mt-0.5 text-ow-primary text-3xl tracking-tight">Lists</h2>
          <ListHeader
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            sortField={sortField}
            setSortField={setSortField}
            dailyOrWeekly={dailyOrWeekly}
            setDailyOrWeekly={setDailyOrWeekly}
          />
          {listsStatistics.toSorted(MyListstatOrder).map((listStatistics) => (
            <ListItem
              listStatistics={listStatistics}
              key={listStatistics.listName}
              previous_percent_change={
                dailyOrWeekly === 'DAILY' ? listStatistics.previous_day_percent_change : listStatistics.previous_week_percent_change
              }
            />
          ))}
        </div>
      )}
      {!loadingCompanies && !companiesError && !listsStatistics.length && (
        <div>
          <h2 className="text-ow-primary text-3xl tracking-tight pt-0.5 mb-6">Lists</h2>
          <p className="text-xs text-left">
            You haven't created any Lists yet. To create your first list, click "Add / remove list" for a company in your{' '}
            <NavLink to="/" className="text-ow-secondary">
              portfolio
            </NavLink>
            . Then click "+ Create new list" to give your first list a name. If you need more assistance, please{' '}
            <NavLink to="/contact" className="text-ow-secondary">
              contact us
            </NavLink>
            .
          </p>
        </div>
      )}
    </div>
  );
}
