import { ComparisonEnum } from '../constants/compare';
import { useRecoilState } from 'recoil';
import { compareSelectedAtom } from '../store/portfolio';

export default function CompareSelector() {
  const [comparisonSelector, setComparisonSelector] = useRecoilState<ComparisonEnum>(compareSelectedAtom);

  const buttonClassNames = 'px-3 pb-3 border-b';
  const tableClassName = `${buttonClassNames} ${comparisonSelector === ComparisonEnum.Table ? 'black border-black' : 'text-ow-secondary border-ow-light-grey'}`;
  const graphClassName = `${buttonClassNames} ${comparisonSelector === ComparisonEnum.Graph ? 'black border-black' : 'text-ow-secondary border-ow-light-grey'}`;
  const timeClassName = `${buttonClassNames} ${comparisonSelector === ComparisonEnum.Time ? 'black border-black' : 'text-ow-secondary border-ow-light-grey'}`;
  const tableImg = `/icons/compare-table${comparisonSelector === ComparisonEnum.Table ? '-selected' : ''}.svg`;
  const tableGraph = `/icons/compare-graph${comparisonSelector === ComparisonEnum.Graph ? '-selected' : ''}.svg`;
  const tableTime = `/icons/compare-time${comparisonSelector === ComparisonEnum.Time ? '-selected' : ''}.svg`;
  return (
    <div>
      <div className="flex justify-end font-bold">
        <div className="flex items-center">
          <button className={tableClassName} onClick={() => setComparisonSelector(ComparisonEnum.Table)}>
            <div className="flex">
              <div className="pr-1">Table</div>
              <div>
                <img src={tableImg} alt={'Table'} className="w-5 h-5 mt-[-3px]" />
              </div>
            </div>
          </button>
          <button className={graphClassName} onClick={() => setComparisonSelector(ComparisonEnum.Graph)}>
            <div className="flex items-center">
              <div className="pr-1">Graph</div>
              <div>
                <img src={tableGraph} alt={'Graph'} />
              </div>
            </div>
          </button>
          <button className={timeClassName} onClick={() => setComparisonSelector(ComparisonEnum.Time)}>
            <div className="flex items-center">
              <div className="pr-1">Time</div>
              <div>
                <img src={tableTime} alt={'Time'} />
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
