import { NavLink } from 'react-router-dom';

export default function FSSError() {
  return (
    <div className="text-center">
      <p className="mb-2">Apologies, this resource is not currently available.</p>
      <p className="text-sm">
        If the problem persists, please get in touch via the{' '}
        <NavLink to="/contact" className="text-ow-secondary">
          contact us
        </NavLink>{' '}
        page.
      </p>
    </div>
  );
}
