import { ITimeseries } from '../types/company';
import { fixedNewDate } from '../utils/dates';

export function getOrderedTimeseries(timeseriesData: ITimeseries[]) {
  timeseriesData.sort(function (a, b) {
    const dateA = fixedNewDate(a.date);
    const dateB = fixedNewDate(b.date);

    if (dateA > dateB) {
      return 1;
    } else if (dateA < dateB) {
      return -1;
    } else {
      return 0;
    }
  });
  return timeseriesData;
}
