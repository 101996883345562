import { atom } from 'recoil';

// each time a user types a letter into the searchBox on the header, this state changes
// in store so that search box can be cleared from other pages
export const searchBoxInputAtom = atom({
  key: 'searchBoxInputAtom',
  default: '',
});

// each time the user presses search, this number is set to the current time in seconds
//   which causes the search page to be reloaded
export const triggerSearchAtom = atom<number>({
  key: 'triggerSearchAtom',
  default: -1,
});
