import Container from '../components/Container';
import SearchComponent from '../components/SearchComponent';
import { useParams } from 'react-router';
import PageHeader from '../components/PageHeader';

export default function SearchView() {
  let { searchTerm } = useParams();

  return (
    <div className="mb-auto">
      <PageHeader pageTitle="Search Results" />
      <Container>
        <div className="lg:flex lg:justify-center lg:w-full">
          <div className="lg:w-full lg:bg-white lg:p-8">
            <div>
              <SearchComponent searchTerm={searchTerm ?? ''} bulkMode={false} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
