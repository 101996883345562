import { useRef } from 'react';
import packageJson from '../../package.json';
import { getTodaysDate } from '../utils/dates';

const onCookieClick = () => {
  // Osano is loaded at the beginning of the index.html script
  // @ts-ignore
  window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
};

export default function Footer() {
  const footerRef = useRef(null);
  const today = getTodaysDate();

  return (
    <div>
      <footer ref={footerRef} className="bg-black text-center text-white">
        <div className="container mx-auto p-6">
          <ul className="flex justify-center text-xxs mb-4">
            <li>
              <a href="https://www.oliverwyman.com/terms-of-use.html" className="text-white px-2">
                Terms Of Use
              </a>
            </li>
            <li>
              <a href="https://www.oliverwyman.com/policies/privacy-notice.html" className="text-white px-2">
                Privacy Notice
              </a>
            </li>
            <li>
              <a href="https://www.oliverwyman.com/terms-of-use/statement-on-accessibility.html" className="text-white px-2">
                Statement On Accessibility
              </a>
            </li>
            <li>
              <a href="https://www.oliverwyman.com/policies/cookie-notice.html" className="text-white px-2">
                Cookie Notice
              </a>
            </li>
            <li>
              {/* Disable eslint so match formatting of the previous links */}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" className="text-white  px-2" onClick={() => onCookieClick()}>
                Manage Cookies
              </a>
            </li>
          </ul>

          <p className="text-xxs mb-4">© {today.getFullYear()} Oliver Wyman INC. All Rights Reserved.</p>
          <p className="text-xxs opacity-50">v{packageJson.version}</p>
        </div>
      </footer>
    </div>
  );
}
