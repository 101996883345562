export default function TextInput({
  value,
  onKeyUpHandler,
  onSearchClickHandler,
  placeholder,
  icon,
  type = 'search',
  disabled = false,
  onKeyPress,
}: Readonly<{
  value: string;
  onKeyUpHandler: any;
  onSearchClickHandler: any;
  placeholder?: string;
  icon?: string;
  type?: string;
  disabled?: boolean;
  onKeyPress: any;
}>) {
  return (
    <div className="flex">
      <input
        autoComplete="off"
        className={`h-8 flex-grow p-2 rounded border border-ow-grey bg-[center_right_0.5rem] bg-no-repeat border-r-0 rounded-r-none disabled:cursor-not-allowed disabled:bg-ow-lighter-grey p-0`}
        type={type}
        name="searchTerm"
        value={value}
        onChange={(e) => {
          onKeyPress(e.target.value);
        }}
        onKeyUp={onKeyUpHandler}
        placeholder={placeholder}
        disabled={disabled}
      />
      <div
        className={`px-5 icon-${icon} bg-center bg-no-repeat border rounded border-ow-grey border-l-0 rounded-l-none`}
        onClick={onSearchClickHandler}
        title={placeholder}
      ></div>
    </div>
  );
}
