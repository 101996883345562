import { useRecoilState } from 'recoil';
import { useState, useEffect, useRef } from 'react';
import { isTrialNoticeCheckedAtom } from '../store/privacy-consent';

export default function TrialConsent() {
  const [isTrialChecked, setIsTrialChecked] = useRecoilState(isTrialNoticeCheckedAtom);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const textBoxRef = useRef<HTMLDivElement>(null);
  const tolerance = 15; // at some zoom level, need some tolerance

  const handleScroll = () => {
    if (textBoxRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = textBoxRef.current;
      if (scrollTop + clientHeight >= scrollHeight - tolerance) {
        console.log('at bottom');
        setIsAtBottom(true);
      }
    }
  };

  useEffect(() => {
    const textBox = textBoxRef.current;
    if (textBox) {
      textBox.addEventListener('scroll', handleScroll);
      return () => {
        textBox.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <div className="text-white text-xs">
      <h1 className="font-mmc text-4xl mb-8 text-white text-center">Trial terms</h1>

      <div ref={textBoxRef} className="scrollbox text-black bg-white overflow-y-scroll p-2 h-96">
        <div className="font-bold">Scroll to the bottom to agree to trial terms</div>
        <div className="text-center mt-2">TRIAL OF FACTIVA SENTIMENT SIGNALS SERVICE</div>
        <div className="text-center mt-2">END USER AGREEMENT</div>
        <div className="text-xs">
          <div className="mt-2">
            By accessing this Factiva Sentiment Signals online service, you agree to abide by the terms set out in this End User Agreement
            (“EUA”). You shall not share your Password with any other individual or entity. Please print and keep a copy of these terms
            after reading them carefully.
          </div>
          <div>
            <table className=" mt-4 border divide-y divide-gray-200  ml-2 mr-2">
              <tr>
                <td className="border border-gray-200 w-1/4 p-2">
                  <b>Factiva</b>
                </td>
                <td className="border border-gray-200 p-2">
                  <b>Factiva, Inc.</b> (a Factiva group company) incorporated in Delaware with offices is at 1211 Avenue of the Americas,
                  New York, NY 10036{' '}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-200 w-1/4 p-2">
                  <b>Evaluation Period</b>
                </td>
                <td className="border border-gray-200 p-2">
                  From the date of signature of this form by You to the end of the 14-day period following the delivery by Factiva of the
                  Licensed Content (“Delivery Date”), as confirmed by Factiva to You by e-mail. The EUA shall automatically terminate at the
                  end of the Evaluation Period.
                </td>
              </tr>
              <tr>
                <td className="border border-gray-200 w-1/4 p-2">
                  <b>Purpose</b>
                </td>
                <td className="border border-gray-200 p-2">
                  Evaluation of the Licensed Content by You, all only in a test/non-production environment.
                </td>
              </tr>{' '}
              <tr>
                <td className="border border-gray-200 w-1/4 p-2">
                  <b>Licensed Content & Access Method</b>
                </td>
                <td className="border border-gray-200 p-2">
                  Predictive signals (the “Factiva Sentiment Signals”) and news articles associated with such predictive signals, relating
                  to eligible companies designated by You (each, and “Identified Company”).
                  <br />
                  <br />
                  You shall access the Licensed Content via the Factiva Sentiment Signals platform (the “Factiva Sentiment Signals
                  Platform”) owned and operated by Factiva’s sentiment signals partner, Oliver Wyman GmbH (“Factiva Partner”). Unless the
                  Factiva Sentiment Signals Platform is indicated separately in the terms below it shall be deemed to fall under the
                  definition of Licensed Content.
                </td>
              </tr>{' '}
              <tr>
                <td className="border border-gray-200 w-1/4 p-2">
                  <b>Personal Information</b>
                </td>
                <td className="border border-gray-200 p-2">
                  Factiva may collect, process and retain your personal information for its business purposes, including sharing same with
                  its partner Oliver Wyman. For more information please see our{' '}
                  <a href="https://www.dowjones.com/privacy-notice">Privacy Notice</a>.
                </td>
              </tr>
            </table>
          </div>
          <div className="mt-4">
            From the date of signature of this form by You to the end of the 14-day period following the delivery by Factiva of the Licensed
            Content (“Delivery Date”), as confirmed by Factiva to You by e-mail. The EUA shall automatically terminate at the end of the
            Evaluation Period. Purpose Evaluation of the Licensed Content by You, all only in a test/non-production environment. Licensed
            Content & Access Method Predictive signals (the “Factiva Sentiment Signals”) and news articles associated with such predictive
            signals, relating to eligible companies designated by You (each, and “Identified Company”). You shall access the Licensed
            Content via the Factiva Sentiment Signals platform (the “Factiva Sentiment Signals Platform”) owned and operated by Factiva’s
            sentiment signals partner, Oliver Wyman GmbH (“Factiva Partner”). Unless the Factiva Sentiment Signals Platform is indicated
            separately in the terms below it shall be deemed to fall under the definition of Licensed Content. Personal Information Factiva
            may collect, process and retain your personal information for its business purposes, including sharing same with its partner
            Oliver Wyman. For more information please see our Privacy Notice (https://www.dowjones.com/privacy-notice).
          </div>
          <div className="list-decimal">
            <li className="mt-2">
              Factiva hereby grants You a limited, non-transferable, non-exclusive, non-assignable, non-sub licensable and royalty free
              licence to access and use the Licensed Content during the Evaluation Period solely in Your internal-to-company operations for
              the Purpose set out on the attached form. You shall have no right to create derived data or content from the Licensed Content.
              You agree not to: (a) disclose or distribute such Licensed Content to any third parties; or (b) use the Licensed Content in a
              production environment or as part of any commercial or other value-generating service or offering, whether internal or
              external. You may make such copies of the Licensed Content as are necessary to evaluate the Licensed Content, but may not make
              any back-up or archival copies and may not load all or any part of the Licensed Content on any computer without Factiva’s
              prior written consent. You acknowledge that if You are bound by a separate set of legal terms (the “Oliver Wyman Terms of
              Use”) which are applicable to the Factiva Sentiment Signals Platform, to the extent of any conflict between this EUA and such
              Oliver Wyman Terms of Use with respect to the usage, handling, and disposition of Licensed Content, this EUA shall control.
            </li>

            <li className="mt-2">
              There shall be no fees due during the Evaluation Period. In consideration for the free license provided hereunder, You may be
              asked to provide feedback, on an ad hoc basis (either periodically or as requested by Factiva or Factiva Partner) during the
              Evaluation Period, and final feedback at the end of the Evaluation Period, to Factiva and Factiva Partner on the content
              structure, tagging and potential of the Licensed Content and You hereby grant to Factiva and Factiva Partner a perpetual
              license to use such feedback to enhance its services and the Licensed Content.
            </li>
            <li className="mt-2">
              The Licensed Content and all related documentation and/or software are proprietary to Factiva or its service providers, and
              the Factiva Sentiment Signals Platform is proprietary to Factiva Partner. You acknowledge that any disclosure pursuant to this
              EUA shall not confer on You and/or Factiva Partner any intellectual property or other rights in relation to the Licensed
              Content. Ownership of all complete or partial copies of the Licensed Content and related documentation and/or software shall
              at all times remain with Factiva and/or Factiva Partner. The codes and data which may be made available with the Licensed
              Content, including, but not limited to, Factiva identifier codes and ISIN and CUSIP data (together the "Codes") are owned by
              either Factiva and/or Factiva Partner, their Affiliates or a third party, and are protected by copyrights, database rights,
              trademarks and/or patents owned by the relevant party. No proprietary rights are being transferred with respect to the Codes.
              Under this EUA, as applicable, You may use the Codes solely to access Licensed Content from the Services and may not otherwise
              use, copy or redistribute the Codes.
            </li>
            <li className="mt-2">
              You acknowledge and agree that the Licensed Content and Factiva Sentiment Signals Platform are provided on an “as is”, “as
              available” basis, and neither Factiva nor Factiva Partner makes any, and each hereby specifically disclaims any
              representations, endorsements, guarantees, or warranties, express or implied, including, without limitation, any of
              merchantability, timeliness, fitness for a particular purpose or any warranties concerning the Licensed Content or the Factiva
              Sentiment Signals Platform being error free, free of viruses or other harmful components, or that it will operate without
              interruption and that the Licensed Content does not constitute advice (investment, tax, or legal) or any recommendation,
              representation, endorsement or arrangement and none of the same should be relied upon by any person for any reason, including,
              without limitation, in connection with any investment decision. In the event that Factiva or Factiva Partner shall be found
              liable to You for any reason other than death or personal injury caused by Factiva’s (or Factiva Partner’s) negligence, the
              sums payable to You in respect of such liability shall not in any circumstances exceed USD 2,000. You understand and agree
              that: (i) Factiva and/or the Factiva Partner shall have the right at any time to change or discontinue all or any portion of
              the Licensed Content or Confidential Information; and (ii) any research or development that You perform is done entirely at
              Your own risk and expense. In particular, neither Factiva nor Factiva Partner shall have any liability in any circumstances
              whatever for any data loss or corruption greater than this limit and You agree that You have sole responsibility for
              protecting Your data in the context of the Evaluation of the Licensed Content under this EUA. The Factiva Sentiment Signals
              Platform may be hosted by a third-party cloud server provider, e.g. AWS. Neither the Factiva nor the Factiva Partner shall
              have any responsibility for any service interruptions and/or any actions and/or omissions by such third-party cloud service
              provider.
            </li>
            <li className="mt-2">
              To the extent legally permitted, neither party shall be liable to the other party for any indirect, special, incidental or
              consequential damages of any nature arising out of the possession of, use of, or inability to use the Licensed Content
              including, without limitation, lost profits, loss of goodwill, work stoppage, data loss, or computer failure or malfunction,
              even if advised of the possibility of such damages, and regardless of whether the claim or liability is based upon any
              contract, tort, breach of warranty or other legal or equitable theory. The foregoing limitation on damages shall not apply to
              Your indemnification obligations hereunder or any infringement by You of Factiva’s (or Factiva Partner’s) intellectual
              property rights.
            </li>
            <li className="mt-2">
              Factiva may revoke, without penalty, the rights granted to You immediately on written notice to You if Factiva has reasonable
              grounds to suspect that You is in breach of any of the terms included herein.
            </li>
            <li className="mt-2">
              Except to the extent that such claims or demands result from the fraud or wilful misconduct of Factiva, You shall indemnify,
              defend and hold harmless Factiva and Factiva Partner, and their affiliates for any loss or damage suffered arising out of any
              third party claim or action alleging or relating to Your use of the Licensed Content and/or Factiva Sentiment Signals Platform
              beyond the rights expressly granted to You hereunder.
            </li>
            <li className="mt-2">
              This agreement and all information relating to the Licensed Content shall be considered Factiva Confidential Information. You
              shall use Factiva’s Confidential Information solely in the context of the Purpose and for no other reason. Each party shall
              limit its disclosure of the other party’s Confidential Information to those of its (and/or the Factiva Partner’s) employees
              with a “need-to-know” such information. All Confidential Information shall remain the exclusive property of the disclosing
              party. Each party shall maintain and shall use prudent methods to cause its employees to maintain the confidentiality and
              secrecy of the other party’s Confidential Information. Each party shall not, and shall use prudent methods to ensure that its
              employees do not copy, publish, disclose to a third person, or use the Confidential Information (other than pursuant to the
              terms hereof). Each party shall return or destroy all copies of the other party’s Confidential Information upon termination of
              this EUA. Notwithstanding the foregoing, Confidential Information shall not include any information to the extent it: (i) is
              or becomes a part of the public domain through no act or omission on the part of the receiving party or its employees; (ii) is
              disclosed to a third party by the disclosing party without restriction on such third party; (iii) is in the receiving party's
              possession, without actual or constructive knowledge of an obligation of confidentiality with respect thereto, at or prior to
              the time of disclosure under this EUA; (iv) is disclosed to the receiving party by a third party having no obligation of
              confidentiality with respect thereto; (v) is independently developed by the receiving party without reference to the
              disclosing party's Confidential Information; or (vi) is required to be disclosed by law, provided that in such instance the
              receiving party will (if not prohibited in law) take reasonable steps to provide the disclosing party with sufficient prior
              notice to contest the order or requirement.
            </li>
            <li className="mt-2">
              At the end of the Evaluation Period, You shall immediately cease evaluation of the Licensed Content, and within ten (10)
              working days of the end of the Evaluation Period completely purge all Licensed Content from its systems and servers.
            </li>
            <li className="mt-2">
              No variation of this EUA shall be effective unless it is in writing and signed by or on behalf of both parties. Neither party
              may assign this EUA, in whole or in part, without the prior written consent of the other party. Neither party will make any
              public statements regarding the existence of this EUA or the relationship described herein, without the prior written consent
              of the other party, except as required by law or as otherwise provided for herein. The following provisions of this EUA will
              survive the termination or expiration of this EUA: Sections 2, 3, 5, 6, 8, 9, 10, and 11. This agreement is explicitly made to
              the benefit of the Factiva Partner (besides the Factiva).
            </li>
            <li className="mt-2">
              This EUA shall be interpreted and construed in accordance with the laws of the State of New York and the parties hereby
              consent to the exclusive jurisdiction of, and venue in, any federal or state court of competent jurisdiction located in the
              Borough of Manhattan, New York City for the purposes of adjudicating any matter arising from or in connection with this EUA.
            </li>
          </div>
        </div>
      </div>

      <label className="inline-flex relative mb-8 mt-8">
        <input
          type="checkbox"
          checked={isTrialChecked}
          disabled={!isAtBottom}
          onChange={() => setIsTrialChecked(!isTrialChecked)}
          className="h-4 w-4 border border-white bg-black checked:bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2"
        />
        {/* <svg className="w-4 h-4 absolute" fill="none" stroke="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"></path>
        </svg> */}

        <div style={{ color: isAtBottom ? 'white' : 'gray' }}>I have read and agree to the Trial Terms.</div>
      </label>
    </div>
  );
}
