import { atom } from 'recoil';
import { IArticleSummary, IGroupedArticles, IThemeWithArticles, IThemeWithGroupedArticles } from '../types/article';
import { articleListSortOptions } from '../constants/articles-list';

export const pageAtom = atom({
  key: 'pageAtom',
  default: 0,
});

export const articlesAtom = atom<IArticleSummary[]>({
  key: 'articlesAtom',
  default: [],
});

export const groupedArticlesAtom = atom<IGroupedArticles[]>({
  key: 'groupedArticlesAtom',
  default: [],
});

export const themesWithArticlesAtom = atom<IThemeWithArticles[]>({
  key: 'themesWithArticlesAtom',
  default: [],
});

export const themesWithGroupedArticlesAtom = atom<IThemeWithGroupedArticles[]>({
  key: 'themesWithGroupedArticlesAtom',
  default: [],
});

export const sortOptionsAtom = atom({
  key: 'sortOptionsAtom',
  default: articleListSortOptions[0],
});

export const allDisplayedAtom = atom({
  key: 'allDisplayedAtom',
  default: false,
});
