import { useEffect } from 'react';
import Container from '../components/Container';
import DowngradeScale from '../components/DowngradeScale';
import useCurrentPage from '../hooks/use-set-current-page';
import { NavLink } from 'react-router-dom';
import PageHeader from '../components/PageHeader';

export default function About() {
  const currentPage = useCurrentPage();

  useEffect(() => {
    currentPage.setCurrentPage('About');
  }, []);

  return (
    <div className="mb-auto">
      <PageHeader pageTitle="How This Works" />
      <Container>
        <div className="lg:flex lg:justify-center lg:w-full">
          <div className="lg:w-full lg:bg-white lg:p-8">
            <h2 className="font-mmc text-xl mb-2">How the Score Works</h2>
            <p className="text-xs mb-4">
              Our Natural Language Models transform words in to scores to directly predict a credit event by using the latest transformer
              models and Oliver Wyman's 30+ years experience in credit quant, which helps you keep track of important changes that you don't
              want to miss. The FSS tool is trained to use an aggregate view of news articles to predict adverse credit events. For
              companies where less 25 news articles have been published within the most recent 6-month period, the FSS score may be volatile
              (presented by a dashed line on the chart).
            </p>

            <h2 className="font-mmc text-xl mb-2 mt-6">FSS Score</h2>
            <p className="text-xs mb-4">
              The scale below shows how the FSS Score ranges translate to easy to understand traffic light signals throughout the
              application. The categories give indicative view of the level of risk detected within the news. Its important to remember that
              typical FSS Score ranges may differ by industry (eg. Petrochemical companies versus eCommerce) or region (eg. USA versus
              Sierra Leone), so whilst some companies may regularly be indicated as "High" others may never get out of the "Very Low" range.
              It is therefore always important to consider the percentage change in FSS Scores for a given entity over time, as well as the
              absolute FSS score.
            </p>

            <div className="max-w-xl mb-4">
              <DowngradeScale />
            </div>

            <p className="text-xs mb-4">
              The likelihood of a company experiencing an adverse credit event when their FSS score is in the ‘Very Low’ category is
              minimal; however, in extreme examples this can happen. For example, testing using the Russell 2000 index showed that c.5% of
              companies with a Very Low score over 2023 experienced a downgrade in that period time. Conversely, c.50% of companies with a
              Very High score were downgraded in 2023
            </p>
            <h2 className="font-mmc text-xl mb-2 mt-6">Need more help?</h2>
            <p className="text-xs mb-4">
              If you get stuck at any point and would like some assistance please{' '}
              <NavLink to="/contact" className="text-ow-secondary">
                let us know.
              </NavLink>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}
