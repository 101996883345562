import { ReactNode, useLayoutEffect } from 'react';
import { createRoot } from 'react-dom/client';
import 'react-multi-carousel/lib/styles.css';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';

// WORKAROUND: Scroll to top when the route changes
const Wrapper = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return <div>{children}</div>;
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <Router>
    <Wrapper>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Wrapper>
  </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
