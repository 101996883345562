import { OktaAuth } from '@okta/okta-auth-js';
import { UserInfo } from '../types/user-info';

import { getOktaAuthServerId, getOktaClientId, getOktaDomain, GetOktaUserClaims } from '../utils/config';

const oktaAuth = new OktaAuth({
  issuer: `https://${getOktaDomain()}/oauth2/${getOktaAuthServerId()}`,
  clientId: getOktaClientId(),
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'email', 'profile'],
});

export const getUserInfo = async (): Promise<UserInfo> => {
  try {
    const initial_token = oktaAuth.getAccessToken() ?? '';
    let client_company = '';
    let client_company_account = '';
    let email = '';
    let name = '';
    let given_name = '';
    let family_name = '';
    let license_entitlement = '';
    let subscriptions = 0; // we need to get this from the validate_user endpoint
    let email_delivery_rate = '';
    let email_domain = '';
    let groups = [] as [string?];
    if (initial_token !== '') {
      const accesstoken = oktaAuth.token.decode(initial_token); // because we have custom claims
      const ui = await GetOktaUserClaims(oktaAuth);
      email = ui?.email as string;
      name = ui?.name as string;
      given_name = ui?.given_name as string;
      family_name = ui?.family_name as string;
      email_delivery_rate = ui?.email_delivery_rate as string;
      let domain_a = ui?.email?.split('@') || [''];
      email_domain = domain_a[domain_a.length - 1] as string;
      groups = accesstoken?.payload?.Groups as [string?];
      // console.log(`xxxx ${JSON.stringify(ui)}`);
      // access token like
      // {"header":{"kid":"L2uHvm-2CEZ1bXbFacJlQmN1hUo0_zghP5fEt1vKQr8","alg":"RS256"},
      // "payload":{"ver":1,"jti":"AT.iUlo8arN9R20jmZKBiU0gMP8onQ7plGMy7mGAYyfGTA",
      //   "iss":"https://wymanpoc.okta.com/oauth2/ausps5xuat8zG61311t7","aud":"api://default","iat":1709809508,
      //   "exp":1709813108,"cid":"0oaprumtslFUKIThw1t7","uid":"00uxjxu93maCcnbvr1t7",
      //   "scp":["email","profile","openid"],"auth_time":1709809506,"sub":"james.uther@gmail.com",
      //    "Organization":"USA","CostCenter":"1234",
      //    "Groups":["Everyone","All OWG and MMC External Users","All OWG External Users","OKTA-NewsTrack-OW"]},
      //    "signature":"c1IFOozTA.....yfYMgAcmu4RTwkfEg"}
      // userinfo like
      // {"sub":"00uxjxu93maCcnbvr1t7",
      //  "name":"Barack Obama","locale":"en_US",
      //  "email":"james.uther@gmail.com",
      //  "preferred_username":"james.uther@gmail.com",
      //  "given_name":"Barack",
      //  "family_name":"Obama",
      //  "zoneinfo":"America/Los_Angeles","updated_at":1708438800,"email_verified":true,
      // "headers":{"cache-control":"no-cache, no-store","content-type":"application/json",
      //      "date":"Thu, 07 Mar 2024 11:10:52 GMT","expires":"0","pragma":"no-cache","
      //       x-okta-request-id":"991b70d57d13e7996efc9ab3a666f0a5","x-rate-limit-limit":"1200",
      //       "x-rate-limit-remaining":"1198","x-rate-limit-reset":"1709809898"}}
      license_entitlement = accesstoken?.payload?.CostCenter as string;
      client_company = accesstoken?.payload?.Organization as string;
      client_company_account = accesstoken?.payload?.Division as string;
    }
    return {
      email: email ?? '',
      name: name ?? '',
      given_name: given_name ?? '',
      family_name: family_name ?? '',
      client_company: client_company ?? '',
      client_company_account: client_company_account ?? '',
      license_entitlement: license_entitlement ?? '',
      subscriptions: subscriptions,
      email_delivery_rate: email_delivery_rate ?? '',
      email_domain: email_domain ?? '',
      groups: groups ?? ([] as [string?]),
    };
  } catch (err) {
    console.error(err);
    return {
      client_company: '',
      client_company_account: '',
      license_entitlement: '',
      email: '',
      name: '',
      given_name: '',
      family_name: '',
      subscriptions: 0,
      email_delivery_rate: '',
      email_domain: '',
      groups: [] as [string?],
    };
  }
};
