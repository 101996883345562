/**
 * Sorting articles in company page list
 */

// INCONSISTENTLY: sort order for theme choice is hard coded in API call get_company_articles, get_articles_for_theme

export const articleListSortOptions = [
  {
    value: 'publication_datetime',
    sortOption: 'publication_datetime desc, title, source_name, modification_datetime desc',
    label: 'Most recent articles',
  },
  {
    value: 'theme_id',
    sortOption: '',
    label: 'Influential themes',
  },
  {
    value: 'Negative',
    sortOption: 'score desc, publication_datetime desc, title, source_name, modification_datetime desc',
    label: 'Most negative articles',
  },
  {
    value: 'Positive',
    sortOption: 'score, publication_datetime desc, title, source_name, modification_datetime desc',
    label: 'Most positive articles',
  },
];
