import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../components/Button';
import Container from '../components/Container';
import PageHeading from '../components/PageHeadings';
import Promo from '../components/Promo';

import { useOktaAuth } from '@okta/okta-react';

export default function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uri = searchParams.get('uri');

  const { oktaAuth, authState } = useOktaAuth();

  const login = () => {
    if (uri) {
      oktaAuth.setOriginalUri(uri);
    }

    oktaAuth.signInWithRedirect();
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      navigate('/');
    }
  }, []);

  return (
    <Container fullHeight={true}>
      <div className="flex justify-between w-full flex-col items-center h-full">
        <div className="lg:w-1/3 justify-center mt-12">
          <div className="flex justify-center w-full mb-5">
            <PageHeading title="Welcome" white />
          </div>
          <div className="w-full flex justify-center">
            <div className="w-1/3">
              <Button text="Login" onClick={login} />
            </div>
          </div>

          <p className="text-white text-center mt-5">
            Experiencing issues?{' '}
            <a href="mailto:fss@oliverwyman.com?subject=FSS - Account request" className="font-bold">
              Get in touch
            </a>
          </p>
        </div>

        <div className="mt-10 mb-6 w-full">
          <Promo />
        </div>
      </div>
    </Container>
  );
}
