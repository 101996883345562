import { useEffect } from 'react';

import useCurrentPage from '../hooks/use-set-current-page';
import Container from '../components/Container';
import EmailDeliverySelector from '../components/EmailDeliverySelector';
import PageHeader from '../components/PageHeader';

export default function Settings() {
  const currentPage = useCurrentPage();
  useEffect(() => {
    currentPage.setCurrentPage('Settings');
  }, []);

  return (
    <div className="mb-auto">
      <PageHeader pageTitle="Email Settings" />
      <Container>
        <div className="lg:flex lg:justify-center lg:w-full">
          <div className="lg:w-full lg:bg-white lg:p-8">
            <EmailDeliverySelector></EmailDeliverySelector>
          </div>
        </div>
      </Container>
    </div>
  );
}
