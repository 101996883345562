import { TooltipData } from '../types/tooltip-data';
import '../styles/CompanyPopup.scss';
import { formatYYYYMMDDForLocale } from '../utils/dates';
import React from 'react';
import { timePeriods } from '../constants/time-units';

const displayDaysInOneBar = (daysInOneBar: number): string => {
  if (daysInOneBar === timePeriods.DAYS) return 'Day: ';
  else if (daysInOneBar === timePeriods.WEEKS) return 'Week: ';
  else if (daysInOneBar === timePeriods.MONTHS) return 'Month: ';
  else if (daysInOneBar === timePeriods.QUARTERS) return 'Quarter: ';
  else if (daysInOneBar === timePeriods.YEARS) return 'Year: ';
  return '';
};

export const CompanyPopup = React.forwardRef((tooltip: TooltipData, ref: React.Ref<any>) => {
  const addStyles = () => {
    if (tooltip.x) {
      return {
        top: (tooltip.y || 0) - 567,
        left: (tooltip.x || 0) - 5,
        marginBottom: -130,
      };
    }
    return {
      top: tooltip.y || 0,
      left: (tooltip.x || 0) - 13,
    };
  };

  return (
    <div ref={ref}>
      <div className="popup" style={addStyles()}>
        <div className="popup-top date">
          <span>{displayDaysInOneBar(tooltip.daysInOneBar)}</span>
          <span>{formatYYYYMMDDForLocale(tooltip.category)}</span>
        </div>
        <div className="popup-bottom">
          <div className="grid grid-cols-3 gap-3">
            <div className="downgrade-score-label">
              FSS
              <br />
              Score
            </div>

            <div className={`col-span-2 downgrade-score-${tooltip.isEstimated ? 'grey' : 'blue'}`}>{tooltip.downgradeScore}</div>

            <div className="total-articles-label">
              Total
              <br />
              Articles
            </div>

            <div className="col-span-2 flex flex-row">
              <div className="total-articles">{(tooltip.positiveArticles + tooltip.negativeArticles).toLocaleString()}</div>

              <div className="positive-negative-articles">
                <div className="positive-articles">+ve: {tooltip.positiveArticles.toLocaleString()}</div>

                <div className="negative-articles">-ve: {tooltip.negativeArticles.toLocaleString()}</div>
              </div>
            </div>
            <div className="col-span-3 separator-line" />
            <div className="col-span-3 news-click">Click column to view articles for this score</div>
          </div>
        </div>
      </div>
    </div>
  );
});
