import { ReactNode } from 'react';

export default function ThemeHeader({ theme, children }: { theme: string; children?: ReactNode }) {
  return (
    <div className="text-white lg:text-black mb-6 bg-black lg:bg-white">
      <div className="container px-6 pb-4 mx-auto">
        <h1 className="text-3xl font-mmc">{theme}</h1>
        <div>{children}</div>
      </div>
    </div>
  );
}
