class NameMarkUp {
  name: string;
  bold: boolean;

  constructor(nameIn: string, boldIn: boolean) {
    this.name = nameIn;
    this.bold = boldIn;
  }
}

const doNameMarkUps = (name: string, searchTerm: string): NameMarkUp[] => {
  const index = name.toLowerCase().indexOf(searchTerm.toLowerCase());
  if (index === -1 && name.length > 0) {
    return [new NameMarkUp(name, false)];
  }
  if (index === -1) {
    return [];
  }

  const firstPart = [
    new NameMarkUp(name.substring(0, index), false),
    new NameMarkUp(name.substring(index, index + searchTerm.length), true),
  ];
  return firstPart.concat(doNameMarkUps(name.substring(index + searchTerm.length), searchTerm));
};

export default function SearchName({
  name,
  searchTerm,
}: Readonly<{
  name: string;
  searchTerm: string;
}>) {
  const boldStyle = {
    fontWeight: 'bold',
  };

  const plainStyle = {};

  const displayName = () => {
    const nameMarkUps = doNameMarkUps(name, searchTerm);
    const result = nameMarkUps.map((nameMarkUp, index) => (
      <span key={index} style={nameMarkUp.bold ? boldStyle : plainStyle}>
        {nameMarkUp.name}
        {''}
      </span>
    ));
    return result;
  };

  return <div>{displayName()}</div>;
}
