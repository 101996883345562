import { useRecoilState, useSetRecoilState } from 'recoil';
import { isTermsAndConditionsOpenAtom } from '../store/terms-and-conditions';
import { isPrivacyNoticeCheckedAtom } from '../store/privacy-consent';

export default function TrialConsent() {
  const [isChecked, setIsChecked] = useRecoilState(isPrivacyNoticeCheckedAtom);
  const setIsTermsAndConditionsOpen = useSetRecoilState(isTermsAndConditionsOpenAtom);

  return (
    <div className="text-white text-xs">
      <h1 className="font-mmc text-4xl mb-8 text-white text-center">Privacy Notice and Terms & Conditions</h1>

      <p className="mb-8">
        In order to continue you must accept our Privacy Notice and Terms and Conditions. You can access Privacy notice{' '}
        <a className="underline" href="https://www.oliverwyman.com/policies/privacy-notice.html">
          here
        </a>{' '}
        and you can access Terms and Conditions{' '}
        <button
          className="underline bg-transparent outline-none focus:outline-none ease-linear transition-all duration-150"
          onClick={() => setIsTermsAndConditionsOpen(true)}
        >
          here
        </button>
        . Once you have read the Privacy Notice, Terms and Conditions, please tick the box below. (You will only be asked to do this once
        for this account).
      </p>

      <label className="inline-flex relative cursor-pointer mb-8">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
          className="h-4 w-4 border border-white bg-black checked:bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2"
        />
        {/* <svg className="w-4 h-4 absolute" fill="none" stroke="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"></path>
        </svg> */}

        <div>I have read and agree to the Privacy Policy.</div>
      </label>
    </div>
  );
}
