import { setTimeFilterModalPreference } from '../utils/local-storage';
import BaseModal from './BaseModal';
import Button from './Button';

export default function TimeFilterModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const handleDontShow = () => {
    setTimeFilterModalPreference();
    onClose();
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} title="Select list or filters to view time chart">
      <div className="flex items-center justify-center mb-4 border border-ow-light-grey">
        <img src="/images/time-filter-helper.png" alt="Highlighting lists and filters" />
      </div>

      <p>
        You are trying to load history for a large number of companies. To make the chart easier to use please select a list or apply
        filters to reduce the number of companies.
      </p>

      <div className="flex justify-end mt-4">
        <button className="mr-4 text-ow-grey" onClick={handleDontShow}>
          Don't show me this again
        </button>
        <Button text="Got it" onClick={onClose} noMargin />
      </div>
    </BaseModal>
  );
}
