import { useEffect } from 'react';
import Container from '../components/Container';
import Button from '../components/Button';
import PageHeading from '../components/PageHeadings';
import useCurrentPage from '../hooks/use-set-current-page';
import useApiCaller from '../hooks/use-api-caller';

export default function MyTest() {
  const currentPage = useCurrentPage();
  const apiCaller = useApiCaller();

  useEffect(() => {
    currentPage.setCurrentPage('MyTest');
  }, []);

  const sendTestEmail = async () => {
    const response = await apiCaller.sendTestEmail();
    console.log('sendTestEmail response', response);
  };

  const sendEmail = async () => {
    const response = await apiCaller.sendEmail();
    console.log('sendEmail response', response);
  };

  return (
    <Container>
      <div className="lg:flex lg:justify-center lg:w-full">
        <div className="lg:w-full lg:bg-white lg:p-8">
          <PageHeading title="Internal Test Page" />

          <Button text="Test email" onClick={() => sendTestEmail()} />

          <Button text="Test daily email trigger" onClick={() => sendEmail()} />
        </div>
      </div>
    </Container>
  );
}
