import { useRecoilState } from 'recoil';
import { currentPageAtom } from '../store/current-page';

export default function useCurrentPage() {
  const [currentPage, setCurrentPageInternal] = useRecoilState(currentPageAtom);

  const get = () => {
    return currentPage;
  };

  const setCurrentPage = (page: string) => {
    setCurrentPageInternal(page);
  };

  return {
    get,
    setCurrentPage,
  };
}
