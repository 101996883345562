import Button from './Button';
import BaseModal from './BaseModal';

export default function DeleteListConfirmationDialog({
  isOpen,
  onClose,
  onSubmit,
  listName,
}: Readonly<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  listName: string;
}>) {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} title={`Delete list '${listName}'?`}>
      <div className="flex justify-end mt-4">
        <div className="mr-4 w-20">
          <Button gray={true} text="No" onClick={onClose} noMargin />
        </div>
        <div className="w-20">
          <Button text="Yes" onClick={onSubmit} noMargin />
        </div>
      </div>
    </BaseModal>
  );
}
