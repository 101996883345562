export const black = '#000000';
export const grey = '#949494';
export const blue = '#009DE0';
export const green = '#00AC41';
export const red = '#C53532';

export const negativeArticlesSeriesName = 'Negative articles';
export const negativeArticlesId = 'negativeArticles';
export const positiveArticlesSeriesName = 'Positive articles';
export const positiveArticlesId = 'positiveArticles';
export const downgradeScoreSeriesName = 'FSS score';
export const downgradeScoreId = 'downgradeScore';
export const estimatedScoreId = 'estimatedScore';
export const ignoreClickClassName = 'ignore-click';

export const GRAPH_FONT_SIZE = '12px';
