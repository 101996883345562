import { useRecoilState } from 'recoil';
import { isTermsAndConditionsOpenAtom } from '../store/terms-and-conditions';

export default function TermsAndConditionsModal() {
  const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] = useRecoilState(isTermsAndConditionsOpenAtom);

  return (
    <div>
      {isTermsAndConditionsModalOpen && (
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 overflow-y-scroll z-30 flex justify-center items-start p-4">
          <div className="bg-white p-6 relative w-full lg:max-w-[75%] xl:max-w-[50%]">
            <button className="absolute top-3 right-3 p-3" onClick={() => setIsTermsAndConditionsModalOpen(false)}>
              <img src="/icons/close-blue.svg" alt="Close" />
            </button>

            <h2 className="font-mmc text-2xl mb-2 text-ow-primary">Agreed Terms</h2>
            <h3 className="font-mmc text-lg">A. Binding Agreement</h3>
            <p className="text-xxs mb-4">
              These terms of use shall apply to your use of the Licensed Content on behalf of yourself and your employer (the “Licensee”).
              By clicking ‘I Agree’, you are agreeing to this Agreement on behalf of yourself and Licensee. References to ‘Licensee’
              hereunder shall also apply to you as an individual.
            </p>
            <h3 className="font-mmc text-lg">B. Defined Terms</h3>
            <p className="text-xxs mb-4">
              <table>
                <tr>
                  <th>Evaluation Period</th>
                  <td>
                    The Evaluation Period shall run for for a period of two (2) weeks from today. The Agreement shall automatically
                    terminate at the end of the Evaluation Period.
                  </td>
                </tr>
                <tr>
                  <th>Purpose</th>
                  <td>Evaluation of the Licensed Content by the Licensee, all only in a test/non-production environment.</td>
                </tr>
                <tr>
                  <th>Licensed Content</th>
                  <td>
                    Predictive signals for credit risk and news articles associated with such predictive signals, relating to up to one
                    hundred (100) companies designated in writing by Licensee, and related news articles. Licensee shall access the Licensed
                    Content via the Factiva Sentiment Signals (FSS) platform owned and operated by Licensor’s pilot project partner, Oliver
                    Wyman GmbH (“Licensor Partner”). Unless FSS platform is mentioned separately in the following it shall be deemed to fall
                    under the definition of Licensed Content.
                  </td>
                </tr>
                <tr>
                  <th>Licensor</th>
                  <td>
                    <b>Dow Jones & Company, Inc.</b> with offices at 1211 Avenue of the Americas, New York, NY 10036 (“<b>Licensor</b>” or “
                    <b>Dow Jones</b>”)
                  </td>
                </tr>
              </table>
            </p>
            <h3 className="font-mmc text-lg">C. Terms of Use</h3>
            <ol className="list-decimal">
              <p className="text-xxs mb-4">
                <li>
                  Licensor hereby grants Licensee a limited, non-transferable, non-exclusive, non-assignable, non-sub licensable and royalty
                  free licence to access and use the Licensed Content during the Evaluation Period solely in its internal operations for the
                  Purpose set out on the attached form. Licensee shall have no right to create derived data or content from the Licensed
                  Content. Licensee agrees not to: (a) disclose or distribute such Licensed Content to any third parties; or (b) use the
                  Licensed Content in a production environment or as part of any commercial or other value-generating service or offering,
                  whether internal or external. Licensee may make such copies of the Licensed Content as are necessary to evaluate the
                  Licensed Content, but may not make any back-up or archival copies and may not load all or any part of the Licensed Content
                  on any computer without Licensor’s prior written consent. The parties acknowledge that if Licensee is bound by a separate
                  set of legal terms (the “Oliver Wyman Terms of Use”) which are applicable to the FSS platform, to the extent of any
                  conflict between this Agreement and such Oliver Wyman Terms of Use with respect to the usage, handling, and disposition of
                  Licensed Content, this Agreement shall control.
                </li>
                <li>
                  There shall be no fees due during the Evaluation Period. In consideration for the free license provided hereunder,
                  Licensee agrees to provide feedback, on an ad hoc basis (either periodically or as requested by Licensor or Licensor
                  Partner) during the Evaluation Period, and final feedback at the end of the Evaluation Period, to Licensor and Licensor
                  Partner on the content structure, tagging and potential of the Licensed Content and hereby grants to Licensor and Licensor
                  Partner a perpetual license to use such feedback to enhance its services and the Licensed Content. Licensor and/or
                  Licensor Partner may reference the Licensee as pilot client towards third parties; accordingly, Licensee hereby grants
                  Licensor and Licensor Partner a worldwide license to use Licensee’s trade name, trademark, logo, brand name, and/or
                  service mark when so referencing Licensee as a pilot client.
                </li>
                <li>
                  The Licensed Content and all related documentation and/or software are proprietary to Licensor or its service providers,
                  and the FSS platform is proprietary to Licensor Partner. Licensee acknowledges that any disclosure pursuant to this
                  Agreement shall not confer on the Licensee and/or Licensor Partner any intellectual property or other rights in relation
                  to the Licensed Content. Ownership of all complete or partial copies of the Licensed Content and related documentation
                  and/or software shall at all times remain with Licensor and/or Licensor Partner. The codes and data which may be made
                  available with the Licensed Content, including, but not limited to, Licensor identifier codes and ISIN and CUSIP data
                  (together the "Codes") are owned by either Licensor and/or Licensor Partner, their Affiliates or a third party, and are
                  protected by copyrights, database rights, trademarks and/or patents owned by the relevant party. No proprietary rights are
                  being transferred with respect to the Codes. Under this Agreement, as applicable, the Licensee may use the Codes solely to
                  access Licensed Content from the Services and may not otherwise use, copy or redistribute the Codes.
                </li>
                <li>
                  Licensee acknowledges and agrees that the Licensed Content and FSS platform are provided on an “as is”, “as available”
                  basis, and neither Licensor nor Licensor Partner makes any, and each hereby specifically disclaims any representations,
                  endorsements, guarantees, or warranties, express or implied, including, without limitation, any of merchantability,
                  timeliness, fitness for a particular purpose or any warranties concerning the Licensed Content or the FSS platform being
                  error free, free of viruses or other harmful components, or that it will operate without interruption and that the
                  Licensed Content does not constitute advice (investment, tax, or legal) or any recommendation, representation, endorsement
                  or arrangement and none of the same should be relied upon by any person for any reason, including, without limitation, in
                  connection with any investment decision. In the event that Licensor or Licensor Partner shall be found liable to Licensee
                  for any reason other than death or personal injury caused by Licensor’s (or Licensor Partner’s) negligence, the sums
                  payable to Licensee in respect of such liability shall not in any circumstances exceed USD 2,000. Licensee understands and
                  agrees that: (i) Licensor and/or the Licensor Partner shall have the right at any time to change or discontinue all or any
                  portion of the Licensed Content or Confidential Information; and (ii) any research or development that Licensee performs
                  is done entirely at its own risk and expense. In particular, neither Licensor nor Licensor Partner shall have any
                  liability in any circumstances whatever for any data loss or corruption greater than this limit and Licensee agrees that
                  it has sole responsibility for protecting its data in the context of the Evaluation of the Licensed Content under this
                  Agreement. The FSS platform may be hosted by a third-party cloud server provider, e.g. AWS. Neither the Licensor nor the
                  Licensor Partner shall have any responsibility for any service interruptions and/or any actions and/or omissions by such
                  third-party cloud service provider.
                </li>
                <li>
                  To the extent legally permitted, neither party shall be liable to the other party for any indirect, special, incidental or
                  consequential damages of any nature arising out of the possession of, use of, or inability to use the Licensed Content
                  including, without limitation, lost profits, loss of goodwill, work stoppage, data loss, or computer failure or
                  malfunction, even if advised of the possibility of such damages, and regardless of whether the claim or liability is based
                  upon any contract, tort, breach of warranty or other legal or equitable theory. The foregoing limitation on damages shall
                  not apply to Licensee’s indemnification obligations hereunder or any infringement by Licensee of Licensor’s (or Licensor
                  Partner’s) intellectual property rights.
                </li>
                <li>
                  Licensor may revoke, without penalty, the rights granted to Licensee immediately on written notice to Licensee if Licensor
                  has reasonable grounds to suspect that Licensee is in breach of any of the terms included herein.
                </li>
                <li>
                  Except to the extent that such claims or demands result from the fraud or wilful misconduct of Licensor, Licensee shall
                  indemnify, defend and hold harmless Licensor and Licensor Partner, and their affiliates for any loss or damage suffered
                  arising out of any third party claim or action alleging or relating to Licensee’s use of the Licensed Content and/or FSS
                  platform beyond the rights expressly granted to Licensee hereunder.
                </li>
                <li>
                  This agreement and all information relating to the Licensed Content shall be considered Licensor Confidential Information.
                  Licensee shall use Licensor’s Confidential Information solely in the context of the Purpose and for no other reason. Each
                  party shall limit its disclosure of the other party’s Confidential Information to those of its (and/or the Licensor
                  Partner’s) employees with a “need-to-know” such information. All Confidential Information shall remain the exclusive
                  property of the disclosing party. Each party shall maintain and shall use prudent methods to cause its employees to
                  maintain the confidentiality and secrecy of the other party’s Confidential Information. Each party shall not, and shall
                  use prudent methods to ensure that its employees do not copy, publish, disclose to a third person, or use the Confidential
                  Information (other than pursuant to the terms hereof). Each party shall return or destroy all copies of the other party’s
                  Confidential Information upon termination of this Agreement. Notwithstanding the foregoing, Confidential Information shall
                  not include any information to the extent it: (i) is or becomes a part of the public domain through no act or omission on
                  the part of the receiving party or its employees; (ii) is disclosed to a third party by the disclosing party without
                  restriction on such third party; (iii) is in the receiving party's possession, without actual or constructive knowledge of
                  an obligation of confidentiality with respect thereto, at or prior to the time of disclosure under this Agreement; (iv) is
                  disclosed to the receiving party by a third party having no obligation of confidentiality with respect thereto; (v) is
                  independently developed by the receiving party without reference to the disclosing party's Confidential Information; or
                  (vi) is required to be disclosed by law, provided that in such instance the receiving party will (if not prohibited in
                  law) take reasonable steps to provide the disclosing party with sufficient prior notice to contest the order or
                  requirement.
                </li>
                <li>
                  At the end of the Evaluation Period, Licensee shall immediately cease evaluation of the Licensed Content, and within ten
                  (10) working days of the end of the Evaluation Period completely purge all Licensed Content from its systems and servers.{' '}
                </li>
                <li>
                  No variation of this Agreement shall be effective unless it is in writing and signed by or on behalf of both parties.
                  Neither party may assign this Agreement, in whole or in part, without the prior written consent of the other party.
                  Neither party will make any public statements regarding the existence of this Agreement or the relationship described
                  herein, without the prior written consent of the other party, except as required by law or as otherwise provided for
                  herein. The following provisions of this Agreement will survive the termination or expiration of this Agreement: Sections
                  2, 3, 5, 6, 8, 9, 10, and 11. This agreement is explicitly made to the benefit of the Licensor Partner (besides the
                  Licensor).
                </li>
                <li>
                  This Agreement shall be interpreted and construed in accordance with the laws of the State of New York and the parties
                  hereby consent to the exclusive jurisdiction of, and venue in, any federal or state court of competent jurisdiction
                  located in the Borough of Manhattan, New York City for the purposes of adjudicating any matter arising from or in
                  connection with this Agreement.
                </li>
              </p>
            </ol>
            <div className="border-b my-6 border-ow-light-grey"></div>
          </div>
        </div>
      )}
    </div>
  );
}
