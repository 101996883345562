import Highcharts from 'highcharts';
import { orderBy } from 'lodash';
import { ICompanySummary } from '../types/company';
import { breakPoints, colorTailwindOrder, scoreBucketName } from '../constants/downgrade-probability';

export const createComparablePercentCompanies = (companies: ICompanySummary[]): Highcharts.PointOptionsObject[] => {
  return companies.map((company) => {
    return { name: company.name, x: company.previous_week_percent_change, y: company.current_score, company_code: company.company_code };
  });
};

const getMinX = (comparablePercentCompanies: Highcharts.PointOptionsObject[]): number => {
  return comparablePercentCompanies.reduce(
    (min: number, comparableCompany: any) => (comparableCompany.x < min ? comparableCompany.x : min),
    0
  );
};

const getMaxX = (comparablePercentCompanies: Highcharts.PointOptionsObject[]): number => {
  return comparablePercentCompanies.reduce(
    (max: number, comparableCompany: any) => (comparableCompany.x > max ? comparableCompany.x : max),
    0
  );
};

export const getPercentChartMinXMaxX = (comparablePercentCompanies: Highcharts.PointOptionsObject[]) => {
  const dataMinX = getMinX(comparablePercentCompanies);
  const dataMaxX = getMaxX(comparablePercentCompanies);
  const distanceX = Math.max(Math.abs(dataMinX), Math.abs(dataMaxX));
  // at least from -5 to +5; add 5 percent buffer
  const bufferX = Math.min(5, distanceX * 0.05);
  const bufferedDistanceX = distanceX + bufferX;
  // results in the unchanged companies in the center of the graph, ie 0 axis is in the center
  return [-bufferedDistanceX, bufferedDistanceX];
};

const filterSort = (comparablePercentCompanies: Highcharts.PointOptionsObject[], minValue: number, maxValue: number) => {
  // if there are two labels that overlap, the first to appear in the list is preferred
  // we prefer labels for companies that have the greatest bad news
  // all things equal, we prefer labels of companies with higher scores
  return orderBy(
    comparablePercentCompanies.filter((c: any) => c.y >= minValue && c.y < maxValue),
    ['x', 'y', 'name'],
    ['desc', 'desc', 'asc']
  );
};

export const createComparableGraphDataSeries = (
  comparablePercentCompanies: Highcharts.PointOptionsObject[]
): Highcharts.SeriesOptionsType[] => {
  return [
    {
      data: filterSort(comparablePercentCompanies, breakPoints[4], 101),
      marker: { fillColor: colorTailwindOrder[5] },
      name: scoreBucketName[5],
      type: 'scatter',
    },
    {
      data: filterSort(comparablePercentCompanies, breakPoints[3], breakPoints[4]),
      marker: { fillColor: colorTailwindOrder[4] },
      name: scoreBucketName[4],
      type: 'scatter',
    },
    {
      data: filterSort(comparablePercentCompanies, breakPoints[2], breakPoints[3]),
      marker: { fillColor: colorTailwindOrder[3] },
      name: scoreBucketName[3],
      type: 'scatter',
    },
    {
      data: filterSort(comparablePercentCompanies, breakPoints[1], breakPoints[2]),
      marker: { fillColor: colorTailwindOrder[2] },
      name: scoreBucketName[2],
      type: 'scatter',
    },
    {
      data: filterSort(comparablePercentCompanies, breakPoints[0], breakPoints[1]),
      marker: { fillColor: colorTailwindOrder[1] },
      name: scoreBucketName[1],
      type: 'scatter',
    },
    {
      data: filterSort(comparablePercentCompanies, 0, breakPoints[0]),
      marker: { fillColor: colorTailwindOrder[0] },
      name: scoreBucketName[0],
      type: 'scatter',
    },
  ];
};
