import { IAlternativeCompany } from '../types/scoring-company';

export const DELETE_ME = 'Delete me';

export const displayCompany = (company: IAlternativeCompany): string => {
  if (company) {
    return `${company.company_code} | ${company.name} | ${company.country} | ${company.my_rank}  | ${company.total}`;
  }
  return '';
};

export const displayCompanyIfNotDeleteMe = (company: IAlternativeCompany): string => {
  if (company.name === DELETE_ME) {
    return '';
  }
  return displayCompany(company);
};

export const parseCompanyCode = (displayedCompany: string): string => {
  // take the part of the drop down string before the first | -- which is the company_code
  const splitList = displayedCompany.split('|');
  return splitList.length > 0 ? splitList[0].trim() : '';
};
export const parseCompanyName = (displayedCompany: string): string => {
  // take the part of the drop down string before the first | -- which is the company_code
  const splitList = displayedCompany.split('|');
  return splitList.length > 1 ? splitList[1].trim() : '';
};
export const parseCompanyCountry = (displayedCompany: string): string => {
  // take the part of the drop down string before the first | -- which is the company_code
  const splitList = displayedCompany.split('|');
  return splitList.length > 2 ? splitList[2].trim() : '';
};
