import Highcharts, { Options } from 'highcharts';
import noDataModule from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import { useMemo, useRef } from 'react';
import { TooltipOptionsExtended } from '../types/tooltip-data';
import '../styles/CompanyGraphs.scss';
import '../extensions/highcharts-plugins';
import ICompany, { ICompanyScoreHistory, ITimeseries } from '../types/company';
import { black, blue, GRAPH_FONT_SIZE, grey } from '../constants/company-chart';
import { createComparableTimeDataSeries, createDataFromTimerangeForCompanies } from '../utils/compare-time-functions';
import { breakPoints, scoreBucketName } from '../constants/downgrade-probability';
import { fixedNewDate, formatYYYYMMDDForLocale } from '../utils/dates';
import { useRecoilValue } from 'recoil';
import { pageDateRangeAtom } from '../store/timerange';
import { getFeatureFlags } from '../utils/config';
import { companyScoreHistoryAtom } from '../store/company';
import useChartTitle from '../hooks/use-chart-title';
import { format } from 'date-fns';

noDataModule(Highcharts);

export default function CompareTime({
  list,
}: Readonly<{
  list?: string;
}>) {
  const ref = useRef<any>();
  const chartRef = useRef(null);
  const pageDateRange = useRecoilValue(pageDateRangeAtom);
  const chartTitle = useChartTitle();

  const companyScoreHistory = useRecoilValue(companyScoreHistoryAtom);

  const formatCompanyData = (company: ICompanyScoreHistory): ICompany => {
    const timeSeries: ITimeseries[] = [];
    let previousScore: number | null = null;
    const earliestDate = new Date(pageDateRange.earliestDate);
    earliestDate.setDate(earliestDate.getDate() - 1);
    let initialDate = earliestDate.toISOString().split('T')[0];
    let currentDate = new Date(initialDate);

    for (let i = 0; i < company.timeseries.length; i++) {
      const scoreValue = company.timeseries[i];
      const isEstimated = company.estimated[i];

      let score: number;
      let total: number;

      if (scoreValue !== null) {
        // Take `main` value if it's not null
        score = scoreValue;
        total = 100;
        previousScore = scoreValue;
      } else if (previousScore !== null) {
        // If both `main` and `estimate` are null, use the previous value
        score = previousScore;
        total = 0;
      } else {
        // Handle edge case if both `main` and `estimate` are null with no previous value
        score = 0;
        total = 0;
      }

      total = isEstimated ? 0 : 100;

      const formattedDate = currentDate.toISOString().split('T')[0];
      timeSeries.push({ score, total, negative: 0, date: formattedDate });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return {
      company_code: company.company_code,
      name: company.name,
      country: company.country,
      industry_sector: company.industry_sector,
      time_series: timeSeries,
    };
  };

  const companyData = useMemo(() => {
    return companyScoreHistory.map((company) => formatCompanyData(company));
  }, [companyScoreHistory]);
  const dataFromTimeRangeForCompanies = useMemo(() => createDataFromTimerangeForCompanies(companyData, pageDateRange), [companyData]);
  const firstDataFromTimeRange = dataFromTimeRangeForCompanies.length > 0 ? dataFromTimeRangeForCompanies[0] : null;

  const tooltipOptions: TooltipOptionsExtended = {
    useHTML: true,
    borderWidth: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    shadow: false,
    // charts crash without this function
    remainOnMouseout: function () {
      return true;
    },
  };

  const defaultPlotLineLabelOptions = {
    y: 20,
    style: { color: '#949494' }, // ow-grey
  };

  const getInitialTickInterval = () => {
    switch (pageDateRange.value) {
      case '5y':
      case '6m':
        return 12;
      case '12m':
        return 4;
      default:
        return 1;
    }
  };

  const getNewTickInvervalOnZoom = (min: number, max: number) => {
    const range = max - min;

    if (pageDateRange.value === '1w') {
      return 1;
    }

    if (range < 20) {
      return 2;
    } else if (range < 40) {
      return 4;
    }

    return getInitialTickInterval();
  };

  const getInitialLabelFormatter = (value: number, min: number, max: number) => {
    const range = max - min;

    if (firstDataFromTimeRange && firstDataFromTimeRange.xAxisCategories[value]) {
      const originalDate = fixedNewDate(firstDataFromTimeRange.xAxisCategories[value]);
      let dateFormat: string = 'MMM yy';
      switch (pageDateRange.value) {
        case '5y':
          if (range < 30) {
            dateFormat = 'dd MMM yy';
          } else if (range < 40) {
            dateFormat = 'MMM yy';
          } else {
            dateFormat = 'yyyy';
          }
          break;
        case '12m':
          if (range < 30) {
            dateFormat = 'dd MMM yy';
          } else {
            dateFormat = 'MMM yy';
          }
          break;
        default:
          dateFormat = 'dd MMM yy';
          break;
      }

      return format(originalDate, dateFormat);
    }

    return '';
  };

  const options: Options = useMemo(
    () => ({
      accessibility: {
        enabled: false,
      },
      chart: {
        animation: true,
        backgroundColor: 'transparent',
        height: 650,
        spacing: [10, 150, 10, 0],
        zooming: {
          type: 'xy',
        },
        resetZoomButton: {
          theme: {
            style: {
              display: 'none',
            },
          },
        },
        events: {
          load: function () {
            this.zoomOut();
          },
        },
      },
      credits: {
        enabled: false,
      },
      lang: {
        noData: '<div style="color:#FF0000">No companies match these filter criteria in your portfolio.</div>',
      },
      legend: {
        enabled: false,
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      noData: {
        useHTML: true,
      },
      series: createComparableTimeDataSeries(dataFromTimeRangeForCompanies),
      title: { text: '' },
      tooltip: tooltipOptions,
      plotOptions: {
        spline: {
          cursor: 'pointer',
          marker: {
            symbol: 'circle',
          },
          events: {
            click: function (s: any) {
              const companyCode = s.point.series.userOptions.custom.companyCode;
              // eslint-disable-next-line no-restricted-globals
              location.pathname = `company/${companyCode}`;
            },
          },
          tooltip: {
            headerFormat: '',
            pointFormatter: function () {
              return `
              <div style="background: #fff;border: 1px solid #dadada;min-width: 140px; border-radius: 10px;z-index: 20;">
                <div style="color:#FFF;background: black;padding: 6px 10px 2px 10px;text-align: center;border-top-left-radius: 10px;border-top-right-radius: 10px;font-weight:bold;">
                  ${this.series.name}
                </div>
                <div style="color:#FFF;background: black;padding: 2px 10px 5px 10px;text-align: center;">
                  ${formatYYYYMMDDForLocale(firstDataFromTimeRange?.xAxisCategories[this.x] as string)}
                </div>
                <div style="display:flex; justify-content:center;padding:10px;">
                  <table >
                    <tr>
                      <td style="text-align: right">FSS<br> Score</td>
                      <td style="padding-left: 10px; color:${this.color}; font-size: 20px;font-weight: 700;line-height: 18px;text-align:right;">${this.y && Math.round(this.y * 100)}</td>
                    </tr>
                  </table>
                </div>
              </div>`;
            },
          },
        },
        series: {
          dataLabels: {
            enabled: false,
            crop: false,
            overflow: 'allow',
            align: 'left',
            verticalAlign: 'middle',
            y: 0,
            x: 10,
            allowOverlap: true,
            style: {
              textOverflow: 'clip',
              width: 140,
            },
            formatter: function () {
              return this.series.name;
            },
          },
        },
      },
      yAxis: [
        {
          title: {
            text: 'FSS Score',
            style: { color: black, fontSize: GRAPH_FONT_SIZE },
          },
          labels: {
            style: { color: black, fontSize: GRAPH_FONT_SIZE },
            formatter: function () {
              const label = this.axis.defaultLabelFormatter.call(this);
              const value = parseFloat(label);
              return `${value * 100}`;
            },
          },
          min: 0.0,
          max: 1.0,
          tickInterval: 0.2,
          lineColor: blue,
          lineWidth: 4,
          plotLines: [
            { color: '#DADADA', value: breakPoints[1] / 100, label: { text: scoreBucketName[1], ...defaultPlotLineLabelOptions } },
            { color: '#DADADA', value: breakPoints[2] / 100, label: { text: scoreBucketName[2], ...defaultPlotLineLabelOptions } },
            { color: '#DADADA', value: breakPoints[3] / 100, label: { text: scoreBucketName[3], ...defaultPlotLineLabelOptions } },
            { color: '#DADADA', value: breakPoints[4] / 100, label: { text: scoreBucketName[4], ...defaultPlotLineLabelOptions } },
            { color: 'transparent', value: 1, label: { text: scoreBucketName[5], ...defaultPlotLineLabelOptions } },
          ], // 'ow-light-grey': ,
          gridLineWidth: 0,
        },
      ],
      ...(firstDataFromTimeRange && {
        xAxis: {
          title: { text: 'Time', margin: 15, style: { color: black, fontSize: GRAPH_FONT_SIZE } },
          lineColor: grey,
          lineWidth: 1,
          type: 'linear',
          tickmarkPlacement: 'on',
          tickWidth: 1,
          tickColor: grey,
          startOnTick: true,
          endOnTick: true,
          minPadding: 0,
          maxPadding: 0,
          labels: {
            style: {
              color: black,
              fontSize: GRAPH_FONT_SIZE,
            },
          },
          events: {
            afterSetExtremes: function (e) {
              const visiblePoints = this.chart.series[0].points.filter((point) => {
                return point.x >= e.min && point.x <= e.max;
              });

              if (visiblePoints.length) {
                this.chart.series.forEach((series) => {
                  const update: Highcharts.SeriesSplineOptions = {
                    type: 'spline',
                    dataLabels: {
                      enabled: true,
                      formatter: function () {
                        if (this.point.index === visiblePoints[visiblePoints.length - 1].index) {
                          return this.series.name;
                        }
                        return null;
                      },
                    },
                  };
                  series.update(update);
                });

                const snappedMin = visiblePoints[0].x;
                const snappedMax = visiblePoints[visiblePoints.length - 1].x;
                this.tickPositions?.reverse();
                this.setExtremes(snappedMin, snappedMax, true, true);

                this.chart.xAxis[0].update({
                  tickInterval: getNewTickInvervalOnZoom(e.min, e.max),
                  labels: {
                    formatter: function () {
                      const value = this.value as number;
                      return getInitialLabelFormatter(value, e.min, e.max);
                    },
                  },
                });
              }
            },
          },
        },
      }),
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 1024,
            },
            chartOptions: {
              chart: {
                spacing: [10, 0, 0, 0],
              },
            },
          },
        ],
      },
      exporting: {
        fallbackToExportServer: false,
        allowHTML: true,
        enabled: true,
        chartOptions: {
          chart: {
            height: 650,
            backgroundColor: 'white',
          },
          tooltip: { enabled: false },
          title: {
            useHTML: true,
            text: chartTitle.formatChartTitle(!!list),
            align: 'center',
          },
        },
        scale: 2,
        sourceWidth: 1360,
        sourceHeight: 650,
        filename: `chart-time-${list || 'Portfolio'}`,
        buttons: {
          contextButton: {
            enabled: false,
          },
        },
      },
    }),
    [dataFromTimeRangeForCompanies]
  );

  const isDownloadChartEnabled = getFeatureFlags().has('download_chart');

  const exportChartAsPNG = () => {
    const chart = (chartRef.current as any)?.chart;
    chart.exportChartLocal();
  };

  const resetZoom = () => {
    const chart = (chartRef.current as any)?.chart;
    chart.zoomOut();
  };

  return (
    <div ref={ref}>
      <div className="flex justify-end items-center mb-4">
        {isDownloadChartEnabled && (
          <button onClick={exportChartAsPNG} className="fss-chart-button" title="Download chart">
            <img src="/icons/download.svg" alt="Download chart" />
            <div>Download chart</div>
          </button>
        )}
        <div className="fss-button-spacer"></div>
        <button onClick={resetZoom} className="fss-chart-button" title="Reset zoom level">
          <img src="/icons/reset.svg" alt="Reset zoom level" />
          <div>Zoom level</div>
        </button>
      </div>

      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
    </div>
  );
}
