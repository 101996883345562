import Select, { MultiValue } from 'react-select';
import { convertToValueLabel, convertFromValueLabel } from '../utils/compare-functions';

export default function MultiSelectDropdown({
  options,
  placeHolder,
  width = '300px',
  selectedOptions,
  onSelectionChange,
}: Readonly<{
  options: string[];
  selectedOptions: MultiValue<{ value: string; label: string }>;
  placeHolder: string;
  width?: string;
  onSelectionChange: (selectedList: string[]) => void;
}>) {
  const handleChange = (selectedList: MultiValue<{ value: string; label: string }>) => {
    onSelectionChange(convertFromValueLabel(selectedList));
  };

  const styles = {
    container: (provided: any) => ({
      ...provided,
      minWidth: 140,
      maxWidth: 400,
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#2C6EF2', // ow-secondary,
    }),
  };

  return (
    <div style={{ width }}>
      <Select
        styles={styles}
        isMulti
        value={selectedOptions}
        onChange={handleChange}
        options={convertToValueLabel(options)}
        placeholder={placeHolder}
      />
    </div>
  );
}
