export const getOktaTokenFromLocalStorage = () => {
  const oktaTokenItem = localStorage.getItem('okta-token-storage') || '{}';

  if (oktaTokenItem) {
    const parsedObject = JSON.parse(oktaTokenItem);
    if (parsedObject?.accessToken?.accessToken) return parsedObject.accessToken.accessToken;
  }

  return '';
};

const timeFilterItemName = 'time-filter-storage';

export const getTimeFilterModalPreference = () => {
  return localStorage.getItem(timeFilterItemName);
};

export const setTimeFilterModalPreference = () => {
  return localStorage.setItem(timeFilterItemName, 'true');
};
