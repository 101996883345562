import { NavLink } from 'react-router-dom';
import { IArticleSummary } from '../types/article';
import parse from 'html-react-parser';
import { formatYYYYMMDDForLocale } from '../utils/dates';
import formatHtmlString from '../utils/format-string';

export default function ArticleItem({
  article,
  companyId = '',
  companyName = '',
  isThemeItem = false,
}: Readonly<{
  article: IArticleSummary;
  companyId?: string;
  companyName?: string;
  isThemeItem?: boolean;
}>) {
  return (
    <div>
      <div className="flex">
        <NavLink to={`/article/${article.dowj_article_id}`} className="text-ow-secondary block text-sm">
          {parse(formatHtmlString(article.title))}
        </NavLink>
      </div>
      <p className="text-ow-dark-grey text-xs">
        <span className="mr-2">{`${formatYYYYMMDDForLocale(article.publication_datetime)} | ${article.source_name}`}</span>

        {typeof article.theme_id != 'undefined' && article.theme_id !== -1 && !isThemeItem && (
          <NavLink
            to={`/theme/${article.theme_id}/${companyId}?theme=${encodeURIComponent(
              article.theme.theme_name
            )}&company=${encodeURIComponent(companyName)}`}
            className="px-0.5 pl-[1.1rem] mt-0.5 -ml-1 whitespace-pre-wrap break-word inline bg-ow-link underline icon-link bg-left bg-no-repeat bg-[length:1rem]"
          >
            {article.theme.theme_name}
          </NavLink>
        )}
      </p>
    </div>
  );
}
