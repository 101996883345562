import { SeriesOptionsType } from 'highcharts';

export interface IDataFromTimerange {
  xAxisCategories: string[];
  xAxisTickInterval: number;
  daysInOneTick: number;
  daysInOneBar: number;
  series: SeriesOptionsType[];
  companyName?: string;
  companyCode?: string;
}

export enum SeriesContentType {
  estimatedSeries = 0,
  mainSeries = 1,
  positiveArticles = 2,
  negativeArticles = 3,
}
