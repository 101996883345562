import { timePeriods } from '../constants/time-units';

export interface IWidthLevels {
  [level: string]: IWidthLevel;
}

export interface ICustomWidthLevel {
  // from and to data is boundary of custom level in months
  fromDataInclusive: number;
  toDataExclusive: number;
  level: number;
  name: string;
}
export interface IWidthLevel {
  name: string;
  minWidth: number;
  maxWidth: number;
  '6m': number;
  '12m': number;
  '5y': number;
  CUSTOM: Array<ICustomWidthLevel>;
}

// made the custom match the hard coded
export const WidthLevels = {
  XLARGE: {
    name: 'X LARGE',
    minWidth: 1600,
    maxWidth: 9999,
    '1w': timePeriods.DAYS, // 7 days
    '6m': timePeriods.DAYS, // 6 months * 30 days = 180
    '12m': timePeriods.WEEKS, // 52 weeks
    '5y': timePeriods.MONTHS, // 12 months/year * 5 years = 60 months
    CUSTOM: [
      { fromDataInclusive: 0, toDataExclusive: 181, level: timePeriods.DAYS, name: 'upToIncluding6Months' },
      { fromDataInclusive: 181, toDataExclusive: 370, level: timePeriods.WEEKS, name: 'upToAYear' },
      { fromDataInclusive: 370, toDataExclusive: 5000, level: timePeriods.MONTHS, name: 'moreThanAYear' },
    ],
  },
  LARGE: {
    name: 'LARGE',
    minWidth: 1024,
    maxWidth: 1599,
    '1w': timePeriods.DAYS, // 7 days
    '6m': timePeriods.WEEKS, // 4  weeks/month * 6 = 24
    '12m': timePeriods.WEEKS, // 52
    '5y': timePeriods.MONTHS, // 12 months/year * 5 years = 60 months
    CUSTOM: [
      { fromDataInclusive: 0, toDataExclusive: 178, level: timePeriods.DAYS, name: 'almostUpTo6Months' },
      { fromDataInclusive: 178, toDataExclusive: 370, level: timePeriods.WEEKS, name: 'upToAYear' },
      { fromDataInclusive: 370, toDataExclusive: 5000, level: timePeriods.MONTHS, name: 'moreThanAYear' },
    ],
  },
  MEDIUM: {
    name: 'MEDIUM - PORTRAIT',
    minWidth: 768,
    maxWidth: 1023,
    '1w': timePeriods.DAYS,
    '6m': timePeriods.WEEKS,
    '12m': timePeriods.WEEKS,
    '5y': timePeriods.MONTHS,
    CUSTOM: [
      { fromDataInclusive: 0, toDataExclusive: 178, level: timePeriods.DAYS, name: 'almostUpTo6Months' },
      { fromDataInclusive: 178, toDataExclusive: 370, level: timePeriods.WEEKS, name: 'upToAYear' },
      { fromDataInclusive: 370, toDataExclusive: 5000, level: timePeriods.MONTHS, name: 'moreThanAYear' },
    ],
  },
  SMALL: {
    name: 'SMALL - PORTRAIT',
    minWidth: 640,
    maxWidth: 767,
    '1w': timePeriods.DAYS,
    '6m': timePeriods.WEEKS,
    '12m': timePeriods.WEEKS,
    '5y': timePeriods.MONTHS,
    CUSTOM: [
      { fromDataInclusive: 0, toDataExclusive: 14, level: timePeriods.DAYS, name: 'upTo2Weeks' },
      { fromDataInclusive: 14, toDataExclusive: 370, level: timePeriods.WEEKS, name: 'upToAYear' },
      { fromDataInclusive: 370, toDataExclusive: 5000, level: timePeriods.MONTHS, name: 'moreThanAYear' },
    ],
  },
  XSMALL: {
    name: 'X SMALL - PORTRAIT',
    minWidth: 430,
    maxWidth: 639,
    '1w': timePeriods.DAYS, //    7
    '6m': timePeriods.MONTHS, //  186
    '12m': timePeriods.MONTHS, //  367
    '5y': timePeriods.QUARTERS, // 1830
    CUSTOM: [
      { fromDataInclusive: 0, toDataExclusive: 14, level: timePeriods.DAYS, name: 'upTo2Weeks' }, // 1 to 13 days
      { fromDataInclusive: 14, toDataExclusive: 70, level: timePeriods.WEEKS, name: 'upTo2Months' },
      { fromDataInclusive: 70, toDataExclusive: 370, level: timePeriods.MONTHS, name: 'upTo6Months' },
      { fromDataInclusive: 370, toDataExclusive: 5000, level: timePeriods.QUARTERS, name: 'moreThanAYear' },
    ],
  },
  XXSMALL: {
    name: 'XX SMALL',
    minWidth: 375,
    maxWidth: 429,
    '1w': timePeriods.DAYS,
    '6m': timePeriods.MONTHS,
    '12m': timePeriods.MONTHS,
    '5y': timePeriods.QUARTERS,
    CUSTOM: [
      { fromDataInclusive: 0, toDataExclusive: 14, level: timePeriods.DAYS, name: 'upTo2Weeks' }, // 1 to 13 days
      { fromDataInclusive: 14, toDataExclusive: 70, level: timePeriods.WEEKS, name: 'upTo2Weeks' }, // 2 to 7 weeks
      { fromDataInclusive: 70, toDataExclusive: 367, level: timePeriods.MONTHS, name: 'upTo6Months' }, //2 to 12 months
      { fromDataInclusive: 367, toDataExclusive: 5000, level: timePeriods.QUARTERS, name: 'moreThanAYear' }, // 20 quarters
    ],
  },
};
