import useCurrentPage from '../hooks/use-set-current-page';
import { useEffect } from 'react';
import Container from '../components/Container';
import PageHeading from '../components/PageHeadings';
import SubscriptionEditorGrid from '../components/SubscriptionEditorGrid';

export default function SubscriptionEditor() {
  const currentPage = useCurrentPage();

  useEffect(() => {
    currentPage.setCurrentPage('SubscriptionEditor');
  }, []);

  return (
    <Container>
      <div className="lg:flex lg:justify-center lg:w-full">
        <div className="lg:w-full lg:bg-white lg:p-8">
          <PageHeading title="Email Subscription Editor" />

          <SubscriptionEditorGrid />
        </div>
      </div>
    </Container>
  );
}
