import { ReactNode } from 'react';

export default function BaseModal({
  isOpen,
  onClose,
  title,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children?: ReactNode;
}) {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 overflow-y-scroll z-30 flex justify-center items-center p-4">
      <div className="bg-white p-6 relative w-full lg:max-w-3xl">
        <div className="flex justify-between items-start mb-4">
          {title && <h2 className="font-mmc text-2xl text-ow-primary">{title}</h2>}
          <div className="p-1 min-w-7 min-h-7 cursor-pointer flex items-center justify-center" onClick={onClose}>
            <img src="/icons/close-blue.svg" alt="Close" />
          </div>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
