import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ICompanySummary, IListStatistics } from '../types/company';
import { getPercentDisplay } from '../utils/format-string';
import DeleteListConfirmationDialog from './DeleteListConfirmationDialog';
import { onUserListDeletion } from '../utils/user-list-functions';
import { companiesAtom } from '../store/portfolio';
import useApiCaller from '../hooks/use-api-caller';

export default function ListItem({
  listStatistics,
  previous_percent_change,
}: Readonly<{
  listStatistics: IListStatistics;
  previous_percent_change: number;
}>) {
  const apiCaller = useApiCaller();
  const [companies, setCompanies] = useRecoilState<ICompanySummary[]>(companiesAtom);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteListName, setDeleteListName] = useState<string>('');

  const onModalClose = (): void => {
    setIsModalOpen(false);
  };

  const onModalSubmit = (): void => {
    setIsModalOpen(false);
    onUserListDeletion(companies, setCompanies, apiCaller, listStatistics.listName);
  };

  const handleDelete = (): void => {
    setDeleteListName(listStatistics.listName);
    setIsModalOpen(true);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  function getRemoveImage() {
    return isHovered ? '/icons/remove_selected.svg' : '/icons/remove.svg';
  }

  return (
    <div>
      <div className="flex justify-between items-center gap-1 mb-2 mt-2">
        <div className="max-w-[50%]">
          <div>
            <div className="mr-4">
              <NavLink to={`/lists/${listStatistics.listName}`}>
                <h3 className="text-ow-secondary fss-company-name">{listStatistics.listName}</h3>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex items-center ">
          <div className="min-w-[3rem] sm:min-w-[7rem] text-center">{listStatistics.numberOfCompanies}</div>
          <div className="min-w-[3rem] sm:min-w-[6.5rem] text-center">{listStatistics.current_score}</div>
          <div className="min-w-[3rem] sm:min-w-[9.5rem] text-center">{getPercentDisplay(previous_percent_change)}</div>
          <div className="min-w-[3rem] sm:min-w-[5.5rem] ml-2 sm:ml-0 m-0 pb-0">
            <div className={`rounded-full text-center text-xs  py-1 px-3 text-black bg-${listStatistics.current_score_color_name}`}>
              <div className="hidden sm:inline">{listStatistics.current_score_text}</div>
            </div>
          </div>
          <div className="hidden md:block min-w-[2rem] sm:min-w-[3rem] m-0 pb-0 py-1 text-right">
            <button onClick={() => handleDelete()} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <img src={getRemoveImage()} alt="Remove" />
            </button>
          </div>
        </div>
      </div>
      <div className="border-b border-ow-light-grey"></div>
      <DeleteListConfirmationDialog isOpen={isModalOpen} onClose={onModalClose} onSubmit={onModalSubmit} listName={deleteListName} />
    </div>
  );
}
