import { useRecoilState } from 'recoil';
import useApiCaller from './use-api-caller';
import { companiesAtom, companiesRefreshCounterAtom } from '../store/portfolio';
import { userInfoAtom } from '../store/user-info';
import { toast } from 'react-toastify';

export default function usePortfolioManager() {
  const apiCaller = useApiCaller();
  const [companiesRefreshCounter, setCompaniesRefreshCounter] = useRecoilState<number>(companiesRefreshCounterAtom);
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const [companies, setCompanies] = useRecoilState(companiesAtom);

  const add = (companyCode: string, name: string) => {
    return new Promise((resolve, reject) => {
      apiCaller
        .addToPortfolio(companyCode)
        .then(() => {
          setCompaniesRefreshCounter(companiesRefreshCounter + 1);

          const userMessage = `Added ${name} to portfolio`;
          setUserInfo({ ...userInfo, subscriptions: userInfo.subscriptions + 1 });
          toast.success(userMessage);
          resolve(userMessage);
        })
        .catch((err) => {
          const userMessage = `Could not add ${name} to portfolio`;
          console.error(userMessage, err);
          toast.error(userMessage);
          reject(userMessage);
        });
    });
  };

  const remove = (companyCode: string, name: string) => {
    return new Promise((resolve, reject) => {
      apiCaller
        .removeFromPortfolio(companyCode)
        .then(() => {
          const localCompanies = [...companies];
          const localCompaniesFiltered = localCompanies.filter((company) => company.company_code !== companyCode);
          setCompanies(localCompaniesFiltered);

          const userMessage = `Removed ${name} from portfolio`;
          setUserInfo({ ...userInfo, subscriptions: userInfo.subscriptions - 1 });
          toast.success(userMessage);
          resolve(userMessage);
        })
        .catch((err) => {
          const userMessage = `Could not remove ${name} from portfolio`;
          console.error(userMessage, err);
          toast.error(userMessage);
          reject(userMessage);
        });
    });
  };

  return {
    add,
    remove,
  };
}
