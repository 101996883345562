import { atom } from 'recoil';

export const filterListsAtom = atom<string[]>({
  key: 'filterListsAtom',
  default: [],
});

export const filterIndustriesAtom = atom<string[]>({
  key: 'filterIndustriesAtom',
  default: [],
});

export const filterCountriesAtom = atom<string[]>({
  key: 'filterCountriesAtom',
  default: [],
});

export const industriesOptionsAtom = atom<string[]>({
  key: 'industriesOptionsAtom',
  default: [],
});

export const countriesOptionsAtom = atom<string[]>({
  key: 'countriesOptionsAtom',
  default: [],
});
