// deprecated: generate temporary SQL in console when not fully migrated to production

import { IScoredUpload } from '../types/scoring-company';

const createCommonTableExpressionEmail = (unique_emails: string[]) => {
  let sql = 'with myEmails as (\n';
  unique_emails.forEach((email: string, index: number) => {
    sql += index === 0 ? '       ' : ' union ';
    sql += `select '${email}' as email \n`;
  });
  sql += `)`;
  return sql;
};

const createCommonTableExpressionEmailCompany = (unique_email_company_codes: IScoredUpload[]) => {
  let sql = 'with myEmailCompanies as (\n';
  unique_email_company_codes.forEach((email_company_code: IScoredUpload, index: number) => {
    sql += index === 0 ? '       ' : ' union ';
    sql += `select '${email_company_code.email}' as email, '${email_company_code.company_code}' as company_code \n`;
  });
  sql += `)`;
  return sql;
};

export const createSQLToAdd = (unique_email_company_codes: IScoredUpload[]) => {
  const unique_emails: string[] = unique_email_company_codes
    .filter((record, index, self) => index === self.findIndex((t) => t.email === record.email))
    .map((scoredUpload: IScoredUpload) => scoredUpload.email);

  const sql_addUser = `
insert into user (email)
${createCommonTableExpressionEmail(unique_emails)}
select email
from myEmails
group by email
having (email) not in (select lower(email)
                       from user
                      )
;`;
  console.log(sql_addUser);

  const sql_addCompanyCode = `
insert into user_company_code (user_id, company_code)
${createCommonTableExpressionEmailCompany(unique_email_company_codes)}
, myUserIdCompanies as (
  select (select id from user where lower(user.email) = myEmailCompanies.email) as user_id
        , company_code
  from myEmailCompanies
)
select user_id, company_code
from myUserIdCompanies
group by user_id, company_code
having (user_id, company_code) not in (select user_id, company_code
                                       from user_company_code
                                       )
;`;

  console.log(sql_addCompanyCode);

  const sql_user_portfolios = `
${createCommonTableExpressionEmail(unique_emails)}
select user.email,  company_code.name, user_company_code.company_code, company_code.industry_sector, company_code.country
from user_company_code
join user on user.id = user_company_code.user_id
join company_code on company_code.company_code = user_company_code.company_code
where lower(user.email) in (select email from myEmails)
order by 1,2
;`;
  console.log(sql_user_portfolios);
};
