import { atom } from 'recoil';

export const isPrivacyNoticeOpenAtom = atom({
  key: 'isPrivacyNoticeOpenAtom',
  default: true,
});

export const isTrialNoticeOpenAtom = atom({
  key: 'isTrialNoticeOpenAtom',
  default: true,
});

export const isPrivacyNoticeCheckedAtom = atom({
  key: 'isPrivacyNoticeCheckedAtom',
  default: false,
});

export const isTrialNoticeCheckedAtom = atom({
  key: 'isTrialNoticeCheckedAtom',
  default: false,
});
