import { ICustomWidthLevel, IWidthLevel, WidthLevels } from '../constants/width-levels';
import { SeriesData } from './series-data';

export class SizeChangeDetector {
  private currentWidthLevel: IWidthLevel = WidthLevels.XXSMALL;

  // TODO: decide if level parameter is needed
  public onLevelChanged?: (level: IWidthLevel) => void;

  constructor() {
    // initialize width level directly
    this.handleResize();
  }

  public attachLevelChangedCallback(levelChangedCallback: (level: IWidthLevel) => void) {
    this.onLevelChanged = levelChangedCallback;
  }

  private getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  private getWidthLevel(width: number): IWidthLevel {
    for (const [, level] of Object.entries(WidthLevels)) {
      if (width >= level.minWidth && width <= level.maxWidth) {
        return level;
      }
    }
    return WidthLevels.XXSMALL;
  }

  /**
   * Public handler to pass over window resize events
   */
  public handleResize(): void {
    const level = this.getWidthLevel(this.getWindowDimensions().width);

    if (level !== this.currentWidthLevel && level.name !== this.currentWidthLevel.name) {
      this.currentWidthLevel = level;

      if (this.onLevelChanged) {
        this.onLevelChanged(level);
      }
    }
  }

  public getLevelDaysByRange(range: any): number {
    const value = range.value;
    let item: number | Array<ICustomWidthLevel> = (this.currentWidthLevel as any)[value];
    let days: number;

    if (value === 'CUSTOM') {
      days = this.getLevelDaysByCustomRange(range, item as Array<ICustomWidthLevel>);
    } else {
      days = item as number;
    }

    return days;
  }

  private getLevelDaysByCustomRange(range: any, customPeriods: Array<ICustomWidthLevel>): number {
    const periodDays = SeriesData.calculateDifferenceInDays(range.earliestDate, range.latestDate);
    for (const [, item] of Object.entries(customPeriods)) {
      if (item.fromDataInclusive <= periodDays && periodDays < item.toDataExclusive) {
        return item.level;
      }
    }
    console.log('WARNING CUSTOM DATE RANGE BREAKPOINT NOT FOUND');
    return 7;
  }

  public getCurrentLevel(): IWidthLevel {
    return this.currentWidthLevel;
  }
}
