import TextInput from '../components/TextInput';
import { useLayoutEffect } from 'react';
import useCurrentPage from '../hooks/use-set-current-page';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { searchBoxInputAtom, triggerSearchAtom } from '../store/search-term';
import { useRecoilState, useSetRecoilState } from 'recoil';

const getSearchTermFromPath = (pathname: string): string => {
  const index = pathname.indexOf('/search/');
  if (index !== 0) {
    return '';
  }
  // 8 is after /search/
  return decodeURIComponent(pathname.substring(8));
};

export default function SearchBox({ pathname, overrideClickFunction }: Readonly<{ pathname: string; overrideClickFunction: any }>) {
  const navigate = useNavigate();

  // each time the user clicks on a key in the search box, searchBoxInput changes state
  const [searchBoxInput, setSearchBoxInput] = useRecoilState(searchBoxInputAtom);
  const setTriggerSearch = useSetRecoilState(triggerSearchAtom);

  const currentPage = useCurrentPage();

  // also the state of the box is read from the URL
  useLayoutEffect(() => {
    const searchTermFromPath = getSearchTermFromPath(pathname);
    if (searchTermFromPath !== '') {
      setSearchBoxInput(searchTermFromPath);
    }
  }, [pathname]);

  const handleChangeEvent = (event: any) => {
    if (event.key === 'Enter') {
      handleOnSearchClickEvent(event);
    }
  };

  const handleOnSearchClickEvent = (event: any) => {
    event.preventDefault();
    overrideClickFunction();
    search();
  };

  const search = () => {
    if (!searchBoxInput || searchBoxInput.length < 2) {
      toast.error('Search should be at least 2 characters.');
      return;
    }
    currentPage.setCurrentPage(`Search - ${searchBoxInput}`);
    navigate(`/search/${searchBoxInput}`);
    // when pressing the search button, we want the search results to refresh from the database even if the URL is not changed
    setTriggerSearch(new Date().getTime());
  };

  return (
    <TextInput
      value={searchBoxInput}
      onKeyUpHandler={handleChangeEvent}
      onSearchClickHandler={handleOnSearchClickEvent}
      onKeyPress={setSearchBoxInput}
      placeholder="Search for a company"
      icon="search"
    />
  );
}
