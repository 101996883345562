import React, { useEffect, useState, useRef, Ref, useImperativeHandle } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useApiCaller from '../hooks/use-api-caller';
import { isSortingInfoModalOpenAtom } from '../store/sorting';
import FilteringInfoModal from './FilteringInfoModal';
import SelectInput from './SelectInput';
import Loading from './Loading';
import { formatYYYYMMDDForLocale, getMonthsAgoYYYYMMDD } from '../utils/dates';
import { articleOverrideDateRangeAtom, pageDateRangeAtom, dateDisplaySeparator } from '../store/timerange';
import FSSError from './FSSError';
import Button from './Button';
import {
  allDisplayedAtom,
  articlesAtom,
  groupedArticlesAtom,
  pageAtom,
  sortOptionsAtom,
  themesWithArticlesAtom,
  themesWithGroupedArticlesAtom,
} from '../store/article';
import '../styles/ArticleList.scss';
import { groupArticles, createThemesWithGroupedArticles } from '../utils/group-articles';
import { IArticleSummary, IGroupedArticles, IThemeWithArticles, IThemeWithGroupedArticles } from '../types/article';
import { isBlank } from '../utils/strings';
import GroupedArticles from './GroupedArticles';
import { articleListSortOptions } from '../constants/articles-list';

type IProps = {
  companyId: string;
  companyName: string;
};

export const ArticleList = React.forwardRef((props: IProps, ref: Ref<HTMLDivElement>) => {
  const innerRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => innerRef.current!, []);

  const apiCaller = useApiCaller();
  const articleListContainerRef = useRef<HTMLDivElement | null>(null);
  const articleRef = useRef<HTMLLIElement | null>(null);
  const themeRef = useRef<HTMLDivElement | null>(null);

  const [selectedSortOption, setSelectedSortOption] = useRecoilState(sortOptionsAtom);

  const handleSortOptionChange = (option: any) => {
    setSelectedSortOption(option);
  };

  const setIsSortingInfoModalOpen = useSetRecoilState(isSortingInfoModalOpenAtom);

  /**
   * Fetch articles / time period
   */
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useRecoilState(pageAtom);
  const numberOfArticlesLoaded = () => {
    return page * apiCaller.ARTICLES_PER_PAGE;
  };

  const location = useLocation();
  const [articles, setArticles] = useRecoilState<IArticleSummary[]>(articlesAtom);
  const [groupedArticles, setGroupedArticles] = useRecoilState<IGroupedArticles[]>(groupedArticlesAtom);
  const [themesWithArticles, setThemesWithArticles] = useRecoilState<IThemeWithArticles[]>(themesWithArticlesAtom);
  const [themesWithGroupedArticles, setThemesWithGroupedArticles] =
    useRecoilState<IThemeWithGroupedArticles[]>(themesWithGroupedArticlesAtom);

  const [allDisplayed, setAllDisplayed] = useRecoilState(allDisplayedAtom);
  const [error, setError] = useState(false);

  const pageDateRange = useRecoilValue(pageDateRangeAtom);
  const [articleOverrideDateRange, setArticleOverrideDateRange] = useRecoilState(articleOverrideDateRangeAtom);

  const companyId = props.companyId;
  const companyName = props.companyName;

  // get start, end dates for article filtering
  //   article calculation is done on the previous 6 months
  // if the pageDateRange is 12 months from 1/1/2022 to 1/1/2023 and the override is null
  //   the start and end date 7/1/2021 to 1/1/2023
  // if the override is populated
  const getArticleStartEndDates = () => {
    let articleStartDate = getMonthsAgoYYYYMMDD(6, pageDateRange.earliestDate);
    let articleEndDate = pageDateRange.latestDate;

    if (!isBlank(articleOverrideDateRange)) {
      const selectedEndDate = articleOverrideDateRange!.slice(-10)!;
      articleStartDate = getMonthsAgoYYYYMMDD(6, selectedEndDate);
      articleEndDate = selectedEndDate;
    }

    return [`${articleStartDate}T00:00:00`, `${articleEndDate}T00:00:00`];
  };

  const displayArticleOverrideDateRange = (articleOverrideDateRange: string | null): string => {
    if (!articleOverrideDateRange) {
      return '';
    }
    // articleOverideDateRange YYYYY-MM-DD - YYYYY-MM-DD
    const startDateYYYYMMDD = articleOverrideDateRange.slice(0, 10);
    const endDateYYYYMMDD = articleOverrideDateRange.slice(-10);

    return `${formatYYYYMMDDForLocale(startDateYYYYMMDD)}${dateDisplaySeparator}${formatYYYYMMDDForLocale(endDateYYYYMMDD)}`;
  };

  const scrollToRef = (ref: React.MutableRefObject<any>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const loadMore = () => {
    setPage((page) => page + 1);
  };

  const scrollToArticle = (): void => {
    scrollToRef(articleRef);
  };

  const scrollToTheme = (): void => {
    scrollToRef(themeRef);
  };

  const fetchArticles = (reset = false) => {
    const articlesAlreadyLoaded = articles.length > 0 && numberOfArticlesLoaded() < articles.length;

    if (!reset && articlesAlreadyLoaded) {
      scrollToArticle();
      return;
    }

    if (loading) return;

    setLoading(true);
    setAllDisplayed(false);

    const [startDate, endDate] = getArticleStartEndDates();

    let sortOption = selectedSortOption.sortOption;
    let filterOption = '';

    if (selectedSortOption.value === 'Positive' || selectedSortOption.value === 'Negative') {
      filterOption = selectedSortOption.value;
    }

    apiCaller
      .getArticles(companyId, sortOption, startDate, endDate, numberOfArticlesLoaded(), apiCaller.ARTICLES_PER_PAGE, filterOption)
      .then((res) => {
        const currentArticles = reset ? [] : articles;
        const newArticles = [...currentArticles, ...res.items];
        setArticles(newArticles);
        const groupedArticlesLocal = groupArticles(newArticles);
        setGroupedArticles(groupedArticlesLocal);

        const articlesLength = newArticles.length;

        if (articlesLength === res.total) {
          setAllDisplayed(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchThemes = (reset = false) => {
    const themesAlreadyLoaded = themesWithArticles.length > 0 && numberOfArticlesLoaded() < themesWithArticles.length;

    if (!reset && themesAlreadyLoaded) {
      scrollToTheme();
      return;
    }

    if (loading) return;

    setLoading(true);
    setAllDisplayed(false);

    const [startDate, endDate] = getArticleStartEndDates();

    apiCaller
      .getThemes(companyId, startDate, endDate, reset ? 0 : numberOfArticlesLoaded(), apiCaller.ARTICLES_PER_PAGE)
      .then((res) => {
        const currentThemes = reset ? [] : themesWithArticles;
        const newThemesWithArticles: IThemeWithArticles[] = [...currentThemes, ...res.items];
        setThemesWithArticles(newThemesWithArticles);
        const themesWithGroupedArticlesLocal: IThemeWithGroupedArticles[] = createThemesWithGroupedArticles(newThemesWithArticles);
        setThemesWithGroupedArticles(themesWithGroupedArticlesLocal);

        if (newThemesWithArticles.length > 0 && res.items.length < 10) {
          setAllDisplayed(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCommon = (reset = false) => {
    if (selectedSortOption.value === 'theme_id') {
      fetchThemes(reset);
    } else {
      fetchArticles(reset);
    }
  };

  const useFirstRender = () => {
    const firstRender = useRef(true);
    useEffect(() => {
      firstRender.current = false;
    }, []);
    return firstRender.current;
  };
  const firstRender = useFirstRender();

  useEffect(() => {
    if (!firstRender) {
      setLoading(true);
      setArticles([]);

      // if page doesn't change no effect handler is called
      if (page !== 0) {
        setPage(0);
      } else {
        fetchCommon(true);
      }
    }
  }, [pageDateRange, selectedSortOption]);

  useEffect(() => {
    if (!firstRender) {
      fetchCommon(true);
      const isValidDate = !isBlank(articleOverrideDateRange);
      if (isValidDate) {
        scrollToRef(articleListContainerRef);
      }
    }
  }, [articleOverrideDateRange]);

  useEffect(() => {
    scrollToArticle();
  }, [articles]);

  useEffect(() => {
    scrollToTheme();
  }, [themesWithArticles]);

  useEffect(() => {
    fetchCommon(page === 0);
  }, [page]);

  return (
    <div ref={articleListContainerRef}>
      <div className="pb-2 mb-2 border-b border-ow-light-grey">
        <div className="flex items-center justify-between flex-wrap">
          <div className="mr-2 align-middle text-xs">
            <h2 className="text-base font-bold inline-block pr-2 mt-1 whitespace-nowrap">Top news stories driving this prediction</h2>
            {articleOverrideDateRange !== '' && (
              <div className="selected-day ignore-click mt-1 mb-2">
                <div>{displayArticleOverrideDateRange(articleOverrideDateRange)}</div>
                <button className="deselect-button" onClick={() => setArticleOverrideDateRange('')}>
                  x
                </button>
              </div>
            )}
          </div>

          <div className="flex justify-end items-center text-xs mt-1.5">
            <p className="text-nowrap whitespace-nowrap">Sort by: &nbsp;</p>
            <SelectInput
              options={articleListSortOptions}
              value={selectedSortOption}
              onChange={handleSortOptionChange}
              minWidth={195}
              textAlign="right"
              color="#2C6EF2"
              bgcolor="#FFFFFF"
            />
            <button onClick={() => setIsSortingInfoModalOpen(true)}>
              <img src="/icons/info.svg" alt="Info" className="ml-1 h-4 w-3" />
            </button>
          </div>
        </div>
      </div>

      {selectedSortOption.value !== 'theme_id' && (
        <div className="articles-list-container">
          {/* <h1>Non-theme sorts</h1> */}
          <GroupedArticles groupedArticles={groupedArticles} companyId={companyId} companyName={companyName} />

          {!allDisplayed && !error && !loading && !!articles.length && (
            <div className="flex justify-center -mb-4">
              <div className="w-full lg:w-[150px]">
                <Button text="Load more" onClick={() => loadMore()} />
              </div>
            </div>
          )}
          {loading && <Loading />}

          {allDisplayed && !!articles.length && <p className="text-center text-xs">All articles loaded.</p>}
          {error && <FSSError />}
        </div>
      )}

      {selectedSortOption.value === 'theme_id' && (
        <div>
          {themesWithGroupedArticles.map((theme, i) => (
            <div ref={i === location.state?.index ? themeRef : undefined} key={theme.id}>
              <h3 className="text-sm mb-3 font-bold text-ow-primary">
                {theme.theme_name ? `${theme.theme_name.charAt(0).toUpperCase()}${theme.theme_name.slice(1)}` : ''}
              </h3>
              {/* <h1>Each three items of themes</h1> */}
              <div className="articles-list-container mb-4 pb-4 border-b border-ow-light-grey">
                <GroupedArticles
                  groupedArticles={theme.groupedArticles}
                  companyId={companyId}
                  companyName={companyName}
                  isThemeItem={true}
                />

                {typeof theme.id != 'undefined' && (
                  <NavLink
                    to={`/theme/${theme.id}/${companyId}?theme=${encodeURIComponent(theme.theme_name)}&company=${encodeURIComponent(
                      companyName
                    )}`}
                    state={{ path: location.pathname, themesWithGroupedArticles: themesWithGroupedArticles, id: theme.id, index: i }}
                    className="px-0.5 mt-0.5 inline text-ow-secondary text-sm"
                  >
                    {'View all >'}
                  </NavLink>
                )}
              </div>
            </div>
          ))}
          {!allDisplayed && !error && !loading && !!themesWithGroupedArticles.length && (
            <div className="flex justify-center -mb-4">
              <div className="w-full lg:w-[150px]">
                <Button text="Load more" onClick={() => loadMore()} />
              </div>
            </div>
          )}
          {loading && <Loading />}

          {allDisplayed && !!themesWithGroupedArticles.length && <p className="text-center text-xs">All themes loaded.</p>}
          {error && <FSSError />}
        </div>
      )}

      {!loading && !error && !articles.length && <p className="text-xs text-center">No articles to display</p>}

      <FilteringInfoModal />
    </div>
  );
});
