import React, { useEffect, useRef, useState } from 'react';
import Button from './Button';
import BaseModal from './BaseModal';

export default function CreateNewList({
  isOpen,
  onClose,
  onSubmit,
}: Readonly<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (inputValue: string) => void;
}>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleOnClose = () => {
    setInputValue('');
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(inputValue);
    handleOnClose();
  };

  if (!isOpen) return null;

  return (
    <BaseModal isOpen={isOpen} onClose={handleOnClose} title="Create new list">
      <div className="mb-2">Enter list name</div>
      <div>
        <input
          ref={inputRef}
          type="text"
          className="w-full p-2 mb-4 border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleOnKeyDown}
        />
      </div>
      <div className="flex justify-end mt-4">
        <div className="mr-4 w-20">
          <Button gray={true} text="Cancel" onClick={handleOnClose} noMargin />
        </div>
        <div className="w-20">
          <Button text="Submit" onClick={handleSubmit} noMargin />
        </div>
      </div>
    </BaseModal>
  );
}
