import { useState } from 'react';
import Select, { MultiValue, MultiValueGenericProps, components } from 'react-select';
import { ADD_NEW_VALUE, convertToValueLabel, convertFromValueLabel, addNewOption, removeNewOption } from '../utils/compare-functions';
import CreateNewList from '../components/CreateNewList';

const CustomOption = (props: any) => {
  const commonClasses = 'font-bold text-sm cursor-pointer';
  const addNewClasses = `${commonClasses} text-ow-secondary cursor-pointer block border-b pb-3`;

  return (
    <div>
      <components.Option {...props}>
        {props.value !== ADD_NEW_VALUE && (
          <input type="checkbox" checked={props.isSelected} onChange={() => null} className="mr-2 cursor-pointer" />
        )}
        <label className={props.value === ADD_NEW_VALUE ? addNewClasses : commonClasses}>{props.label}</label>
      </components.Option>
    </div>
  );
};

const CustomMultiValueContainer = (props: MultiValueGenericProps<{ value: string; label: string }>) => {
  const values = props.selectProps.value as { value: string; label: string }[];

  if (values.length > 5) return <div className="fss-multiple-list-item">{`In ${values.length} lists...`}</div>;

  if (values) {
    return values[values.length - 1].label === props.data.label ? props.data.label : props.data.label + ', ';
  } else return '';
};

const CustomDropdownIndicator = (props: any) => {
  return (
    <div {...props.innerProps}>
      <img src="/icons/filter-sort.svg" alt="Filter / sort icon" className="ml-1" />
    </div>
  );
};

export default function MultiSelectAddNewItemDropdown({
  options,
  initialUserList,
  placeHolder,
  width = '300px',
  customIndicator = true,
  onSelectionChange,
}: Readonly<{
  options: string[];
  initialUserList: string[];
  placeHolder: string;
  width?: string;
  customIndicator?: boolean;
  onSelectionChange: (selectedList: string[]) => void;
}>) {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<{ value: string; label: string }>>(
    convertToValueLabel(initialUserList)
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onModalClose = (): void => {
    setIsModalOpen(false);
  };

  const addToSelectedList = (cloneSelectedList: any) => {
    // conversion also sorts and makes unique
    const selectedValueLabelSorted = convertToValueLabel(convertFromValueLabel(removeNewOption(cloneSelectedList)));
    setSelectedOptions(selectedValueLabelSorted);
    onSelectionChange(convertFromValueLabel(selectedValueLabelSorted));
  };

  const onModalSubmit = (newLabel: string): void => {
    setIsModalOpen(false);

    //remove csv challenging values
    newLabel = newLabel.trim().replace(/"/g, '').replace(/,/g, '');
    if (newLabel.length > 0) {
      let cloneSelectedList = [...selectedOptions];
      cloneSelectedList.push({ value: newLabel, label: newLabel });
      addToSelectedList(cloneSelectedList);
    }
  };

  const handleChange = (selectedList: MultiValue<{ value: string; label: string }>) => {
    const index = selectedList.findIndex((item) => item.value === ADD_NEW_VALUE);
    let cloneSelectedList = [...selectedList];
    if (index !== -1) {
      setIsModalOpen(true);
      return;
    }

    addToSelectedList(cloneSelectedList);
  };

  const styles = {
    option: (provided: any) => ({
      ...provided,
      background: 'none',
      color: '#343D40', // ow-darker-grey
      paddingBottom: 0,
      paddingTop: 0,
      marginTop: 10,
      marginBottom: 10,
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#2C6EF2', // ow-secondary,
    }),
    control: (provided: any) => ({
      ...provided,
      border: 'none',
      cursor: 'pointer',
      boxShadow: 'none',
      minHeight: '32px',
      paddingLeft: '0.5rem',
      ...(customIndicator && { paddingRight: '0.5rem' }),
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      background: 'none',
      fontSize: '0.875rem',
      padding: 0,
      border: 0,
      margin: 0,
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      fontSize: '100%',
      padding: 0,
      paddingLeft: 0,
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      position: 'absolute',
      ...(customIndicator && { fontStyle: 'italic' }),
    }),
  };

  return (
    <div style={{ width: width }}>
      <Select
        isMulti
        styles={styles}
        value={selectedOptions}
        onChange={handleChange}
        options={addNewOption(convertToValueLabel(options))}
        placeholder={placeHolder}
        hideSelectedOptions={false}
        isClearable={false}
        isSearchable={false}
        components={{
          Option: CustomOption,
          MultiValueContainer: CustomMultiValueContainer,
          ...(customIndicator && { DropdownIndicator: CustomDropdownIndicator }),
        }}
        className={'add-new-item-dropdown'}
        classNamePrefix={'add-new-item-dropdown'}
      />
      <CreateNewList isOpen={isModalOpen} onClose={onModalClose} onSubmit={onModalSubmit} />
    </div>
  );
}
