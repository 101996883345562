import { NavLink, useSearchParams } from 'react-router-dom';
import Container from '../components/Container';
import PageHeading from '../components/PageHeadings';

export default function NotFound() {
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');

  return (
    <Container>
      <PageHeading title="Page not found!" />
      <div className="text-center lg:text-left">
        {error && <p className="mb-6">{error}</p>}

        <NavLink className="text-ow-secondary" to="/">
          Back to Portfolio
        </NavLink>
      </div>
    </Container>
  );
}
