import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { ICompanySummary } from '../types/company';
import PortfolioListItem from './PortfolioListItem';
import PortfolioListHeader from './PortfolioListHeader';
import { compareNames } from '../utils/compare-functions';
import { dailyWeeklyAtom } from '../store/portfolio';

export default function PortfolioList({
  filteredCompanies,
  lists,
  hideList,
}: Readonly<{
  filteredCompanies: ICompanySummary[];
  lists: string[];
  hideList: boolean;
}>) {
  const [sortDirection, setSortDirection] = useState<string>('UP');
  const [sortField, setSortField] = useState<string>('NAME');

  const [dailyOrWeekly, setDailyOrWeekly] = useRecoilState<string>(dailyWeeklyAtom);

  const compareCompanySortOrder = (companyA: ICompanySummary, companyB: ICompanySummary): number => {
    if (sortField === 'NAME') {
      return compareNames(companyA.sortable_name, companyB.sortable_name, sortDirection);
    } else if (sortField === 'DOWNGRADE') {
      const sortOrderDifference =
        sortDirection === 'DOWN'
          ? companyA.current_score_sort_order - companyB.current_score_sort_order
          : companyB.current_score_sort_order - companyA.current_score_sort_order;

      if (sortOrderDifference === 0) {
        return compareNames(companyA.sortable_name, companyB.sortable_name, 'UP');
      }
      return sortOrderDifference;
    } else if (sortField === 'SCORE') {
      const scoreDifference =
        sortDirection === 'UP' ? companyA.current_score - companyB.current_score : companyB.current_score - companyA.current_score;

      if (scoreDifference === 0) {
        return compareNames(companyA.sortable_name, companyB.sortable_name, 'UP');
      }
      return scoreDifference;
    } else if (sortField === 'PERCENT_CHANGE') {
      let scoreDifference = 0;
      if (dailyOrWeekly === 'DAILY') {
        scoreDifference =
          sortDirection === 'UP'
            ? companyA.previous_day_percent_change - companyB.previous_day_percent_change
            : companyB.previous_day_percent_change - companyA.previous_day_percent_change;
      } else {
        scoreDifference =
          sortDirection === 'UP'
            ? companyA.previous_week_percent_change - companyB.previous_week_percent_change
            : companyB.previous_week_percent_change - companyA.previous_week_percent_change;
      }
      if (scoreDifference === 0) {
        return compareNames(companyA.sortable_name, companyB.sortable_name, 'UP');
      }
      return scoreDifference;
    } else if (sortField === 'INDUSTRY') {
      let scoreDifference = compareNames(companyA.industry_sector, companyB.industry_sector, sortDirection);
      if (scoreDifference === 0) {
        return compareNames(companyA.sortable_name, companyB.sortable_name, 'UP');
      }
      return scoreDifference;
    } else if (sortField === 'USER_LIST') {
      // Sort by lists alphabetically, empty lists display last
      const a = companyA.user_list.join();
      const b = companyB.user_list.join();
      if (a === '') return 1;
      if (b === '') return -1;

      const difference = compareNames(a, b, sortDirection);
      // If lists match, secondary sort by company name alphabetically
      if (difference === 0) {
        return compareNames(companyA.sortable_name, companyB.sortable_name, 'UP');
      }
      return difference;
    }
    console.log('Sort field not yet supported');
    return 0;
  };

  return (
    <div>
      <PortfolioListHeader
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        sortField={sortField}
        setSortField={setSortField}
        dailyOrWeekly={dailyOrWeekly}
        setDailyOrWeekly={setDailyOrWeekly}
        hideList={hideList}
      />
      {filteredCompanies.toSorted(compareCompanySortOrder).map((filteredCompany) => (
        <PortfolioListItem
          key={filteredCompany.company_code}
          name={filteredCompany.name}
          location={filteredCompany.country}
          company_code={filteredCompany.company_code}
          current_score={filteredCompany.current_score}
          current_score_color_name={filteredCompany.current_score_color_name}
          current_score_text={filteredCompany.current_score_text}
          previous_percent_change={
            dailyOrWeekly === 'DAILY' ? filteredCompany.previous_day_percent_change : filteredCompany.previous_week_percent_change
          }
          industry={filteredCompany.industry_sector}
          lists={lists}
          list={filteredCompany.user_list}
          hideList={hideList}
        />
      ))}
    </div>
  );
}
