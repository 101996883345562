import { MouseEventHandler } from 'react';

export default function Button({
  text,
  onClick,
  type = 'button',
  disabled = false,
  noMargin = false,
  gray = false,
}: Readonly<{
  text: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit';
  disabled?: boolean;
  noMargin?: boolean;
  gray?: boolean;
}>) {
  const backgroundColor = gray ? 'bg-gray-500' : 'bg-ow-secondary';

  return (
    <div>
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={`w-full ${backgroundColor} text-white font-mmc p-2 disabled:opacity-60 disabled:cursor-not-allowed ${
          noMargin ? '' : 'mb-4'
        }`}
      >
        {text}
      </button>
    </div>
  );
}
