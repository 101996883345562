import parse from 'html-react-parser';
import { useState } from 'react';
import formatHtmlString from '../utils/format-string';
import iso6391 from 'iso-639-1';

export default function ArticleHeader({
  title,
  source_name,
  date,
  wordCount,
  source_logo,
  byline,
  language_code,
}: Readonly<{
  title: string;
  source_name: string;
  date: string;
  wordCount: number;
  source_logo: string;
  byline: string;
  language_code: string | undefined;
}>) {
  const [logoLoadError, setLogoLoadError] = useState(false);

  const language = iso6391.getName(language_code ?? '');
  const displayLanguage = language ? `, ${language}` : '';

  const onLogoLoadError = () => {
    setLogoLoadError(true);
  };

  return (
    <div className=" mb-6">
      <div className="container px-6 mx-auto">
        {!logoLoadError && (
          <div className="mt-6 mb-6">
            <img src={source_logo} alt="Logo" onError={() => onLogoLoadError()} />
          </div>
        )}
        <div>
          <h1 className={`text-xl font-mmc mb-6`}>{parse(formatHtmlString(title))}</h1>
          <div className={`text-sm whitespace-pre-line overflow-x-auto text-gray-500 mb-2 font-bold`}>{byline}</div>
          <p className="text-xs">
            {source_name}, {date}, {wordCount} words{displayLanguage}
          </p>
        </div>
      </div>
    </div>
  );
}
