import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import useApiCaller from '../hooks/use-api-caller';
import '../styles/PortfolioListItem.scss';
import MultiSelectAddNewItemDropdown from './MultiSelectAddNewItemDropdown';
import { getPercentDisplay } from '../utils/format-string';
import { useRecoilState } from 'recoil';
import { companiesAtom } from '../store/portfolio';
import { ICompanySummary } from '../types/company';
import { onUserListAssignmentChange } from '../utils/user-list-functions';
import usePortfolioManager from '../hooks/use-portfolio-manager';

export default function PortfolioListItem({
  name,
  location,
  company_code,
  current_score,
  current_score_text,
  current_score_color_name,
  previous_percent_change,
  industry,
  lists,
  list,
  hideList,
}: Readonly<{
  name: string;
  location: string;
  company_code: string;
  current_score: number;
  current_score_text: string;
  current_score_color_name: string;
  previous_percent_change: number;
  industry: string;
  lists: string[];
  list: string[];
  hideList: boolean;
}>) {
  const apiCaller = useApiCaller();
  const portfolioManager = usePortfolioManager();

  const [companies, setCompanies] = useRecoilState<ICompanySummary[]>(companiesAtom);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  function handleRemoveCompanyClick() {
    portfolioManager.remove(company_code, name);
  }

  function getRemoveImage() {
    return isHovered ? '/icons/remove_selected.svg' : '/icons/remove.svg';
  }

  function getRemoveImageAlt() {
    return 'removeImg';
  }

  const onSelectionChange = (selectedList: string[]): void => {
    onUserListAssignmentChange(companies, setCompanies, apiCaller, company_code, name, selectedList);
  };

  return (
    <div>
      <div className="flex justify-between items-center gap-1 mb-2 mt-2">
        <div className="max-w-[50%]">
          <NavLink to={`/company/${company_code}`}>
            <div>
              <div className="mr-4">
                <h3 className="fss-company-name">{name}</h3>
                <p className="text-xs fss-company-country">{location}</p>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="flex items-center ">
          <div className="hidden lg:inline">
            <div className="w-[300px] mr-2 text-left">{industry}</div>
          </div>
          {!hideList && (
            <div className="hidden xl:inline mr-2 portfolilo-add-new-item">
              <MultiSelectAddNewItemDropdown
                options={lists}
                initialUserList={list}
                placeHolder={'Add / remove list'}
                onSelectionChange={onSelectionChange}
              />
            </div>
          )}
          <div className="min-w-[3rem] sm:min-w-[5rem] text-center">{Math.round(current_score)}</div>
          <div className="min-w-[3rem] sm:min-w-[7rem] text-center">{getPercentDisplay(previous_percent_change)}</div>
          <div className="min-w-[3rem] sm:min-w-[5.5rem] ml-2 sm:ml-0 m-0 pb-0">
            <div className={`rounded-full text-center text-xs  py-1 px-1 text-black bg-${current_score_color_name}`}>
              <div className="hidden sm:block">{current_score_text}</div>
            </div>
          </div>
          <div className="hidden md:block min-w-[2rem] sm:min-w-[3rem] m-0 pb-0 py-1 text-right">
            <button onClick={() => handleRemoveCompanyClick()} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <img src={getRemoveImage()} alt={getRemoveImageAlt()} />
            </button>
          </div>
        </div>
      </div>
      <div className="border-b border-ow-light-grey"></div>
    </div>
  );
}
