import Container from '../components/Container';
import ContactForm from '../components/ContactForm';
import PageHeader from '../components/PageHeader';

export default function Contact() {
  return (
    <div className="mb-auto">
      <PageHeader pageTitle="Contact" />
      <Container>
        <div className="lg:flex lg:justify-center lg:w-full">
          <div className="lg:w-full bg-white sm:p-4 lg:p-8">
            <ContactForm />
          </div>
        </div>
      </Container>
    </div>
  );
}
