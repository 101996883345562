import {
  estimatedScoreId,
  grey,
  downgradeScoreId,
  downgradeScoreSeriesName,
  blue,
  positiveArticlesSeriesName,
  positiveArticlesId,
  green,
  negativeArticlesSeriesName,
  negativeArticlesId,
  red,
} from '../constants/company-chart';
import { IntervalLengthConstants } from '../constants/interval-length-constants';
import { ISplittedSeries, ITimeseries } from '../types/company';
import { IDataFromTimerange } from '../types/company-chart';
import moment from 'moment';

const ARTICLE_THRESHOLD: number = 25;

export class SeriesData {
  private readonly timeseries: ITimeseries[];
  private currentPageDateRange: any;
  private currentSeriesData!: IDataFromTimerange;

  constructor(timeseries: ITimeseries[]) {
    this.timeseries = timeseries;
  }

  /**
   * Calculates the amount of days per interval
   *
   * @param months the amount of months shown on xAxis
   * @returns the amount of days per interval
   */
  public static getIntervalLength(months: number): number {
    if (months <= 2) {
      return IntervalLengthConstants.Day;
    }
    if (months <= 4) {
      return IntervalLengthConstants.Week;
    }
    if (months <= 24) {
      return IntervalLengthConstants.Month;
    }
    if (months <= 36) {
      return IntervalLengthConstants.Quarter;
    }
    return IntervalLengthConstants.Year;
  }

  /**
   * Calculates the amount of months for a given period based on
   * start date and end date.
   *
   * @param earliestDate start date
   * @param latestDate end date
   * @returns size of period in months
   */
  public static calculateDifferenceInMonths(earliestDate: string, latestDate: string): number {
    return moment(latestDate, 'YYYY-MM-DD').diff(moment(earliestDate, 'YYYY-MM-DD'), 'months');
  }

  public static calculateDifferenceInDays(earliestDate: string, latestDate: string): number {
    return moment(latestDate, 'YYYY-MM-DD').diff(moment(earliestDate, 'YYYY-MM-DD'), 'days');
  }

  /**
   * Entry point for a new calculation of chart data.
   *
   * @param pageDateRange the given time period the chart is showing
   * @param daysInOneBar the actual size of one item in bar chart, dependent on current screen width
   * @returns current version of IDataFromTimerange chart series data
   */
  public calculateDataForTimerange(pageDateRange: any, daysInOneBar: number): IDataFromTimerange {
    if (this.currentPageDateRange === pageDateRange) {
      // avoid unnecessary recalculation
      return this.currentSeriesData;
    }

    this.currentPageDateRange = pageDateRange;
    this.calculateSeriesData(daysInOneBar);

    return this.currentSeriesData;
  }

  // returns the amount of axis items per interval
  private getTickInterval(monthDifference: number, daysInOneBar: number): number {
    if (monthDifference <= 6) {
      return 1;
    }
    if (monthDifference <= 12) {
      return 2;
    }
    if (monthDifference <= 60) {
      return 12 / (daysInOneBar / 30);
    }
    return 52;
  }

  private getDowngradeDataForTimerange(daysInOneTick: number): ITimeseries[] {
    // important to keep clone: timeSeriesData should be unchanged as we manipulate the clone by adding default records
    const timeSeriesClone = structuredClone(this.timeseries);
    const timeSeriesWithDefaults: ITimeseries[] = [];

    // parsing the earliest date into a moment object
    const earliestDateMoment = moment(this.currentPageDateRange.earliestDate, 'YYYY-MM-DD').subtract(daysInOneTick, 'days');

    // parsing and setting the initial start date
    const startInterval = moment(this.currentPageDateRange.latestDate, 'YYYY-MM-DD').subtract(daysInOneTick, 'days');

    // parsing the initial end date
    const endInterval = moment(this.currentPageDateRange.latestDate, 'YYYY-MM-DD');

    for (
      ;
      startInterval.isSameOrAfter(earliestDateMoment);
      endInterval.subtract(daysInOneTick, 'days'), startInterval.subtract(daysInOneTick, 'days')
    ) {
      const startIntervalYYYYMMDD = startInterval.format('YYYY-MM-DD');
      const endIntervalYYYYMMDD = endInterval.format('YYYY-MM-DD');

      const defaultObject = { date: endIntervalYYYYMMDD, score: 0, total: 0, negative: 0, estimatedScore: true };

      // fetch elements of one interval e.g. a week or a month...
      const intervalObjects = timeSeriesClone.reduce(
        (acc, obj) => (obj.date >= startIntervalYYYYMMDD && obj.date < endIntervalYYYYMMDD ? [...acc, obj] : acc),
        [] as ITimeseries[]
      );

      // ... then fetch last element of the according interval or add default one if empty...
      const defaultedObject = intervalObjects[intervalObjects.length - 1] || defaultObject;

      // ... and set the end date
      defaultedObject.date = endIntervalYYYYMMDD;

      timeSeriesWithDefaults.push(defaultedObject);
    }

    // final object needs earliest date first for graphing purposes
    timeSeriesWithDefaults.reverse();

    // now go through the array from oldest to newest defaulting values
    let lastScore = 0;
    for (let i = 0; i < timeSeriesWithDefaults.length; i++) {
      if (timeSeriesWithDefaults[i].estimatedScore) {
        timeSeriesWithDefaults[i].score = lastScore;
      }
      lastScore = timeSeriesWithDefaults[i].score;
    }

    // now if the total number of articles is less than the threshold, change to an estimatedScore
    // previously set to 0, but that no longer works when we want the score to be the previous value
    for (let j = 0; j < timeSeriesWithDefaults.length; j++) {
      if (timeSeriesWithDefaults[j].total < ARTICLE_THRESHOLD) {
        timeSeriesWithDefaults[j].estimatedScore = true;
      }
    }

    return timeSeriesWithDefaults;
  }

  /**
     *
        ERP-731: The code splits one series in valid and estimated scores. The gap in the
        line chart is achieved by adding null values in the series.
     * @param completeSeries the full series with valid and estimated scores inside
     * @returns an object of main and estimated series arrays
     */
  public getSplittedSeries(completeSeries: ITimeseries[]): ISplittedSeries {
    let mainSeries: (number | null)[] = [];
    let estimatedSeries: (number | null)[] = [];

    for (let i = 0; i < completeSeries.length; i++) {
      const roundedScore = Math.round(completeSeries[i].score * 100) / 100;

      if (completeSeries[i].estimatedScore) {
        mainSeries[i] = null;
        estimatedSeries[i] = roundedScore;
        if (i > 0 && !completeSeries[i - 1].estimatedScore) {
          mainSeries[i - 1] = completeSeries[i - 1].score;
          estimatedSeries[i - 1] = completeSeries[i - 1].score;
        }
      } else {
        mainSeries[i] = roundedScore;
        estimatedSeries[i] = null;
        if (i > 0 && completeSeries[i - 1].estimatedScore) {
          mainSeries[i] = completeSeries[i].score;
          estimatedSeries[i] = completeSeries[i].score;
        }
      }
    }

    return {
      mainSeries: mainSeries,
      estimatedSeries: estimatedSeries,
    };
  }
  /**
   *  main calculation method
   */
  private calculateSeriesData(daysInOneBar: number) {
    let downgradeDataForTimerange: ITimeseries[] = [];

    const monthDifference = SeriesData.calculateDifferenceInMonths(
      this.currentPageDateRange.earliestDate,
      this.currentPageDateRange.latestDate
    );

    const tickInterval = this.getTickInterval(monthDifference, daysInOneBar);

    downgradeDataForTimerange = this.getDowngradeDataForTimerange(daysInOneBar);

    const negativeArticles = downgradeDataForTimerange.map((item) => item.negative || 0);
    // Make the values here negative so the positive article bar shows up under the axis
    const positiveArticles = downgradeDataForTimerange.map((item) => -(item.total - item.negative) || 0);

    const splittedSeries = this.getSplittedSeries(downgradeDataForTimerange);

    const xAxisCategories = downgradeDataForTimerange.map((item) => item.date);

    const daysInOneTick = SeriesData.getIntervalLength(monthDifference);
    // const daysInOneTick = tickInterval * daysInOneBar;

    this.currentSeriesData = {
      xAxisCategories: xAxisCategories,
      xAxisTickInterval: tickInterval, // amount of x axis items per interval for the given time period
      daysInOneTick: daysInOneTick, // amount of days in one tick interval
      daysInOneBar: daysInOneBar, // isn't it the same like daysInOneTick?? TBC!
      series: [
        {
          name: 'Low articles',
          id: estimatedScoreId,
          color: grey,
          type: 'spline',
          dashStyle: 'Dash',
          lineWidth: 1,
          data: splittedSeries.estimatedSeries,
          linkedTo: downgradeScoreId,
          showInLegend: true,
          marker: {
            enabled: false,
          },
          animation: {
            duration: 1000,
          },
          yAxis: 0,
          zIndex: 2,
          stacking: 'normal',
          stack: 1,
        },
        {
          name: downgradeScoreSeriesName,
          id: downgradeScoreId,
          color: blue,
          type: 'spline',
          data: splittedSeries.mainSeries,
          showInLegend: true,
          marker: {
            enabled: false,
          },
          animation: {
            duration: 1000,
          },
          yAxis: 0,
          zIndex: 2,
        },
        {
          name: positiveArticlesSeriesName,
          id: positiveArticlesId,
          color: green,
          type: 'column',
          data: positiveArticles,
          yAxis: 1,
          zIndex: 1,
        },
        {
          name: negativeArticlesSeriesName,
          id: negativeArticlesId,
          color: red,
          type: 'column',
          data: negativeArticles,
          yAxis: 1,
          zIndex: 1,
        },
      ],
    };
  }
}
