import Highcharts from 'highcharts';
import ICompany from '../types/company';
import { SeriesData } from './series-data';
import { SizeChangeDetector } from './size-change-detector';
import { IDataFromTimerange } from '../types/company-chart';
import { IPageDateRange } from '../types/timerange';

const getDataFromTimeRange = (company: ICompany, pageDateRange: IPageDateRange) => {
  const seriesData = new SeriesData(company.time_series);
  const sizeChangeDetector = new SizeChangeDetector();
  const daysInOneBar = sizeChangeDetector.getLevelDaysByRange(pageDateRange);
  return {
    ...seriesData.calculateDataForTimerange(pageDateRange, daysInOneBar),
    companyName: company.name,
    companyCode: company.company_code,
  };
};

export const createDataFromTimerangeForCompanies = (companies: ICompany[], pageDateRange: IPageDateRange): IDataFromTimerange[] => {
  return companies.map((company) => getDataFromTimeRange(company, pageDateRange));
};

// Create data labels for the last point in a series to display company names on the right of the chart
const createSeriesWithDataPointLabels = (series: Highcharts.SeriesSplineOptions) => {
  series.dataLabels = {
    enabled: true,
    formatter: function () {
      if (this.point.index === this.series.data.length - 1) {
        return this.series.name;
      }
      return null;
    },
  };

  return series;
};

export const createComparableTimeDataSeries = (dataFromTimeRangeForCompanies: IDataFromTimerange[]): Highcharts.SeriesOptionsType[] => {
  return dataFromTimeRangeForCompanies
    .map((dataFromTimeRange: IDataFromTimerange, index) => {
      // Override series IDs and options
      dataFromTimeRange.series[0].id = `company-${index}-estimate`;
      dataFromTimeRange.series[1].id = `company-${index}-downgrade`;
      dataFromTimeRange.series[0].name = dataFromTimeRange.companyName;
      dataFromTimeRange.series[1].name = dataFromTimeRange.companyName;
      dataFromTimeRange.series[0].custom = { companyCode: dataFromTimeRange.companyCode };
      dataFromTimeRange.series[1].custom = { companyCode: dataFromTimeRange.companyCode };

      // Note: workaround for 'property x does not exist on y' error
      // Ref: https://github.com/highcharts/highcharts-angular?tab=readme-ov-file#property-xxx-does-not-exist-on-type-yyy
      (dataFromTimeRange.series[0] as Highcharts.SeriesSplineOptions).linkedTo = `company-${index}-downgrade`;
      (dataFromTimeRange.series[0] as Highcharts.SeriesSplineOptions).stacking = undefined;

      const estimateSeries = createSeriesWithDataPointLabels(dataFromTimeRange.series[0] as Highcharts.SeriesSplineOptions);
      const mainSeries = createSeriesWithDataPointLabels(dataFromTimeRange.series[1] as Highcharts.SeriesSplineOptions);

      return [estimateSeries, mainSeries];
    })
    .flat();
};
