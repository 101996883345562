import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NavLink } from 'react-router-dom';
import { companiesAtom, compareSelectedAtom, userListStatsAtom } from '../store/portfolio';
import { ICompanySummary, IListStatistics } from '../types/company';
import { ComparisonEnum } from '../constants/compare';

export function CompanyListSelector() {
  const companies = useRecoilValue<ICompanySummary[]>(companiesAtom);
  const listStatistics = useRecoilValue<IListStatistics[]>(userListStatsAtom);
  const setComparisonSelector = useSetRecoilState(compareSelectedAtom);

  const getLinkClasses = ({ isActive }: { isActive: boolean }) => {
    const className = 'fss-portfolio-selector';
    return isActive ? `${className} ${className}--selected` : className;
  };

  return (
    <div className="container mx-auto lg:px-8 text-base">
      <div className="flex">
        <NavLink to={`/`} className={getLinkClasses} onClick={() => setComparisonSelector(ComparisonEnum.Table)}>
          <span>Companies</span> <span className="fss-portfolio-selector__pill">{companies.length}</span>
        </NavLink>
        <NavLink to={`/lists`} className={getLinkClasses} onClick={() => setComparisonSelector(ComparisonEnum.Table)}>
          <span>Lists</span> <span className="fss-portfolio-selector__pill">{listStatistics.length}</span>
        </NavLink>
      </div>
    </div>
  );
}
