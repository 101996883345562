import { datadogRum } from '@datadog/browser-rum';

import { getDatadogApplicationId, getDatadogClientToken, getDatadogSite, getDatadogService, getDatadogEnv } from './config';

const skipLogging = () => {
  return getDatadogEnv() === 'local';
};

export const dataDogInit = () => {
  if (skipLogging()) {
    return;
  }

  datadogRum.init({
    applicationId: getDatadogApplicationId()!,
    clientToken: getDatadogClientToken()!,
    site: getDatadogSite(),
    service: getDatadogService(),
    env: getDatadogEnv(),
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: ‘1.0.0’,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    silentMultipleInit: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
};

export const dataDogAddAction = (action: string, actionProperties: object) => {
  if (skipLogging()) {
    return;
  }
  datadogRum.addAction(action, actionProperties);
};

export const dataDogSetUser = (userObject: any) => {
  if (skipLogging()) {
    return;
  }
  datadogRum.setUser(userObject);
};
