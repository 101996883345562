import Spinner from './Spinner';
// import { useSearchParams } from 'react-router-dom';
// import useApiCaller from '../hooks/use-api-caller';

export default function Loading() {
  // // const queryParameters = new URLSearchParams(window.location.search)
  // const [queryParameters] = useSearchParams();

  // const code = queryParameters.get('code') || '';
  // const state = queryParameters.get('state') || '';
  // const apiCaller = useApiCaller();

  // try {
  //   // ping the api endpoint saying we're here. Ignore errors
  //   apiCaller.login(code, state).finally();
  // } finally {
  // }

  return (
    <div className="flex justify-center">
      <Spinner />
    </div>
  );
}
