import { atom } from 'recoil';
import { UserInfo } from '../types/user-info';
import { dataDogSetUser } from '../utils/data-dog-logging';

export const userInfoAtom = atom<UserInfo>({
  key: 'userInfoAtom',
  default: {
    client_company: '',
    client_company_account: '',
    license_entitlement: '',
    email: '',
    name: '',
    given_name: '',
    family_name: '',
    subscriptions: 0,
    email_delivery_rate: '',
    email_domain: '',
    groups: [],
  },
  effects: [
    ({ onSet }) => {
      onSet((n) => {
        dataDogSetUser({
          id: n.email,
          name: n.name,
          email: n.email,
          client_company_account: n.client_company_account,
          client_company: n.client_company,
          subscriptions: n.subscriptions,
          email_delivery_rate: n.email_delivery_rate,
          email_domain: n.email_domain,
          groups: n.groups,
        });
      });
    },
  ],
});
