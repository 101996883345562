import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useApiCaller from '../hooks/use-api-caller';
import { ICompanySummary } from '../types/company';
import Loading from './Loading';
import PortfolioList from './PortfolioList';
import FSSError from './FSSError';
import CompareGraph from './CompareGraph';
import CompareTime from './CompareTime';
import CompareFilters from './CompareFilters';
import { ComparisonEnum } from '../constants/compare';
import { getUniqueCountries, getUniqueIndustries, areUserListsInCommon } from '../utils/compare-functions';
import { companiesErrorAtom, companiesAtom, compareSelectedAtom, loadingCompaniesAtom, listsAtom } from '../store/portfolio';
import { countriesOptionsAtom, filterCountriesAtom, filterIndustriesAtom, filterListsAtom, industriesOptionsAtom } from '../store/filters';
import CompareSelector from './CompareSelector';
import { pageDateRangeAtom } from '../store/timerange';
import { companyScoreHistoryAtom } from '../store/company';
import TimeFilterModal from './TimeFilterModal';
import { getTimeFilterModalPreference } from '../utils/local-storage';

export default function Companies({
  defaultList,
}: Readonly<{
  defaultList: string[];
}>) {
  const apiCaller = useApiCaller();

  const pageDateRange = useRecoilValue(pageDateRangeAtom);

  const loadingCompanies = useRecoilValue<boolean>(loadingCompaniesAtom);
  const companiesError = useRecoilValue<boolean>(companiesErrorAtom);
  const companies = useRecoilValue<ICompanySummary[]>(companiesAtom);
  const lists = useRecoilValue<string[]>(listsAtom);

  const [comparisonSelector, setComparisonSelector] = useRecoilState(compareSelectedAtom);

  const [filterLists, setFilterLists] = useRecoilState(filterListsAtom);
  const [filterIndustries, setFilterIndustries] = useRecoilState(filterIndustriesAtom);
  const [filterCountries, setFilterCountries] = useRecoilState(filterCountriesAtom);

  useEffect(() => {
    if (defaultList) {
      setFilterLists(defaultList);
    } else {
      setFilterLists([]);
    }
  }, defaultList);

  const [filteredCompanies, setFilteredCompanies] = useState<ICompanySummary[]>([]);

  const [industriesOptions, setIndustriesOptions] = useRecoilState(industriesOptionsAtom);
  const [countriesOptions, setcountriesOptions] = useRecoilState(countriesOptionsAtom);

  const setCompanyScoreHistory = useSetRecoilState(companyScoreHistoryAtom);
  const [historyLoading, setHistoryLoading] = useState(true);

  // ----------------------------------------------------------------------------
  // history
  const [isTimeFilterModalOpen, setIsTimeFilterModalOpen] = useState(false);
  const shouldDisplayTimeFilterModal = getTimeFilterModalPreference();

  const getCompanyScoreHistory = async () => {
    setHistoryLoading(true);
    const ids = filteredCompanies.map((company) => company.company_code).join(',');
    const earliestDate = new Date(pageDateRange.earliestDate);
    earliestDate.setDate(earliestDate.getDate() - 1);

    await apiCaller
      .getPortfolioWithHistory(ids, earliestDate.toISOString().split('T')[0], pageDateRange.latestDate)
      .then((res) => setCompanyScoreHistory(res))
      .catch(() => setCompanyScoreHistory([])); // TODO: Error toast

    setHistoryLoading(false);
  };

  useEffect(() => {
    if (comparisonSelector === ComparisonEnum.Time) {
      const compNum = filteredCompanies.length;
      // Fetch history
      if (!!compNum) {
        getCompanyScoreHistory();

        // Display helper modal
        if (compNum > 100 && !shouldDisplayTimeFilterModal) {
          setIsTimeFilterModalOpen(true);
        }
      } else {
        setCompanyScoreHistory([]);
      }
    }
  }, [filteredCompanies, pageDateRange, comparisonSelector]);

  // ----------------------------------------------------------------------------
  // filtering
  useEffect(() => {
    const filteredCompaniesLocal = companies
      .filter((company) => (filterLists.length > 0 ? areUserListsInCommon(filterLists, companies, company.company_code) : true))
      .filter((company) => (filterIndustries.length > 0 ? filterIndustries.includes(company.industry_sector) : true))
      .filter((company) => (filterCountries.length > 0 ? filterCountries.includes(company.country) : true));
    setFilteredCompanies(filteredCompaniesLocal);
  }, [companies, filterLists, filterCountries, filterIndustries]);

  useEffect(() => {
    setcountriesOptions(getUniqueCountries(companies, defaultList[0]));
    setIndustriesOptions(getUniqueIndustries(companies, defaultList[0]));
  }, [companies]);

  // ----------------------------------------------------------------------------
  useEffect(() => {
    // On mount
    setComparisonSelector(ComparisonEnum.Table);

    // On unmount
    return () => {
      // Reset filters
      setFilterCountries([]);
      setFilterIndustries([]);
      setFilterLists([]);
    };
  }, []);

  return (
    <div>
      {loadingCompanies && <Loading />}
      {companiesError && <FSSError />}
      {!loadingCompanies && !companiesError && companies.length !== 0 && (
        <div className="companies-list-container">
          <div className="hidden lg:flex mb-4 justify-between flex-row-reverse">
            <div className="min-w-[2rem] sm:min-w-[18.5rem] text-left pt-1.5">
              <CompareSelector />
            </div>
            <div className={comparisonSelector === ComparisonEnum.Table ? 'opacity-0' : ''}>
              <CompareFilters lists={lists} industries={industriesOptions} countries={countriesOptions} hideList={defaultList.length > 0} />
            </div>
            {!defaultList.length && comparisonSelector === ComparisonEnum.Table && (
              <h2 className="text-ow-primary text-3xl tracking-tight">Companies</h2>
            )}
          </div>
          <div>
            <div>
              {comparisonSelector === ComparisonEnum.Table && (
                <PortfolioList filteredCompanies={filteredCompanies} lists={lists} hideList={defaultList.length > 0} />
              )}
              {comparisonSelector === ComparisonEnum.Graph && (
                <CompareGraph filteredCompanies={filteredCompanies} allCompanies={companies} list={defaultList[0]} />
              )}
              {comparisonSelector === ComparisonEnum.Time && historyLoading && (
                <div className="min-h-[500px] flex items-center justify-center">
                  <div className="flex flex-col font-bold">
                    <Loading />
                    <div className="mt-4">History loading...</div>
                  </div>
                </div>
              )}
              {comparisonSelector === ComparisonEnum.Time && !historyLoading && <CompareTime list={defaultList[0]} />}
            </div>
          </div>
        </div>
      )}
      <TimeFilterModal isOpen={isTimeFilterModalOpen} onClose={() => setIsTimeFilterModalOpen(false)} />
      {!loadingCompanies && !companiesError && !companies.length && (
        <div>
          <h2 className="text-ow-primary text-3xl tracking-tight pt-0.5 mb-6">Companies</h2>
          <p className="text-xs text-left">
            There are no companies in your portfolio yet. To add a company, use the Search field in the top bar and then click the green "+"
            symbol to add your first company. If you need more assistance, please{' '}
            <NavLink to="/contact" className="text-ow-secondary">
              contact us
            </NavLink>{' '}
            .
          </p>
        </div>
      )}
    </div>
  );
}
