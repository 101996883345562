import Select, { MultiValue, components } from 'react-select';
import { convertFromValueLabel, convertToValueLabel } from '../utils/compare-functions';
import { SetterOrUpdater } from 'recoil';

const CustomOption = (props: any) => {
  const isSortValue = props.value === 'UP' || props.value === 'DOWN';
  const isClearValue = props.value === 'CLEAR_ALL';

  return (
    <div>
      <components.Option {...props}>
        {!isSortValue && !isClearValue && (
          <input type="checkbox" checked={props.isSelected} onChange={() => null} className="mr-2 cursor-pointer" />
        )}
        <label className={`font-bold text-sm cursor-pointer ${isSortValue || isClearValue ? 'text-ow-secondary' : 'text-ow-darker-grey'}`}>
          {props.label}
        </label>
      </components.Option>
    </div>
  );
};

const CustomMultiValue = () => null;

const CustomValueContainer = (props: any) => {
  return (
    <div {...props.innerProps} className="mt-0.5">
      {props.header}
      {props.children}
    </div>
  );
};

const CustomDropdownIndicator = (props: any) => {
  return <img src="/icons/filter-sort.svg" alt="Filter / sort icon" className="ml-1" />;
};

export default function TableHeaderSortFilter({
  header,
  selectLabel = 'Select filters',
  sortField,
  options,
  selectedOptions,
  onSelectionChange,
  onSortChange,
  clearAllSetter,
}: Readonly<{
  header: string;
  selectLabel?: string;
  sortField: string;
  options: string[];
  selectedOptions: MultiValue<{ value: string; label: string }>;
  onSelectionChange: (selectedList: string[]) => void;
  onSortChange: (field: string, sortDirection: string) => void;
  clearAllSetter: SetterOrUpdater<string[]>;
}>) {
  const formatGroupOptions = (options: string[]) => {
    return [
      {
        label: 'Sort:',
        options: [
          { value: 'UP', label: 'Sort A - Z' },
          { value: 'DOWN', label: 'Sort Z - A' },
        ],
      },
      {
        label: `${selectLabel}:`,
        options: [{ value: 'CLEAR_ALL', label: 'Clear all' }, ...convertToValueLabel(options)],
      },
    ];
  };

  const handleChange = (selectedList: MultiValue<{ value: string; label: string }>) => {
    const sortItem = selectedList.find((item) => item.value === 'UP' || item.value === 'DOWN');
    const clearItem = selectedList.find((item) => item.value === 'CLEAR_ALL');

    if (sortItem) {
      onSortChange(sortField, sortItem.value);
      return;
    }

    if (clearItem) {
      clearAllSetter([]);
      return;
    }

    onSelectionChange(convertFromValueLabel(selectedList));
  };

  const styles = {
    option: (provided: any) => ({
      ...provided,
      background: 'none',
      paddingBottom: 0,
      paddingTop: 0,
      marginTop: 10,
      marginBottom: 10,
      textTransform: 'none',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    control: (provided: any) => ({
      ...provided,
      border: 'none',
      cursor: 'pointer',
      boxShadow: 'none',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#2C6EF2', // ow-secondary,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided: any) => ({
      ...provided,
      width: '300px',
    }),
    menuList: (provided: any) => {
      const optionCount = options.length;
      const menuHeight = Math.min(optionCount * 30, 500);
      return {
        ...provided,
        maxHeight: '500px',
        height: `${menuHeight + 168}px`,
        overflowY: 'auto',
        width: '300px',
      };
    },
    groupHeading: (provided: any) => ({
      ...provided,
      textTransform: 'none',
      fontSize: '0.75rem',
      color: '#000',
    }),
    group: (provided: any) => ({
      ...provided,
      paddingBottom: 0,
    }),
  };

  const renderCustomValueContainer = (props: any) => {
    return <CustomValueContainer header={header} {...props} />;
  };

  return (
    <div>
      <Select
        isMulti
        styles={styles}
        value={selectedOptions}
        onChange={handleChange}
        options={formatGroupOptions(options)}
        placeholder=""
        hideSelectedOptions={false}
        isClearable={false}
        isSearchable={false}
        components={{
          Option: CustomOption,
          MultiValue: CustomMultiValue,
          ValueContainer: renderCustomValueContainer,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        className={'header-sort-filter-dropdown'}
        classNamePrefix={'header-sort-filter-dropdown'}
      />
    </div>
  );
}
