export default function formatHtmlString(text: string): string {
  // eslint-disable-next-line no-useless-escape
  return text.replace(/\\/g, '/').replace(/\"/, '"').replace(/ ,/, ',');
}

function getPercentSign(percent_change: number) {
  return percent_change < 0 ? '' : '+';
}

export function getPercentDisplay(percent_change: number) {
  const value = Math.round(percent_change * 10) / 10;
  return `${getPercentSign(value)}${value.toFixed(1)}%`;
}
