import { NavLink } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { listsAtom } from '../store/portfolio';
import { convertToValueLabel } from '../utils/compare-functions';
import TableHeaderSortFilter from './TableHeaderSortFilter';
import { countriesOptionsAtom, filterCountriesAtom, filterIndustriesAtom, filterListsAtom, industriesOptionsAtom } from '../store/filters';

export default function PortfolioListHeader({
  sortDirection,
  setSortDirection,
  sortField,
  setSortField,
  dailyOrWeekly,
  setDailyOrWeekly,
  hideList,
}: Readonly<{
  sortDirection: string;
  setSortDirection: (direction: string) => void;
  sortField: string;
  setSortField: (field: string) => void;
  dailyOrWeekly: string;
  setDailyOrWeekly: (field: string) => void;
  hideList: boolean;
}>) {
  function setSort(field: string, defaultDirection: string, singleControl: boolean = false) {
    if (singleControl) {
      setSortDirection(sortDirection === 'DOWN' ? 'UP' : 'DOWN');
    } else {
      setSortDirection(defaultDirection);
    }

    setSortField(field);
  }
  function toggleDaily() {
    setDailyOrWeekly(dailyOrWeekly === 'DAILY' ? 'WEEKLY' : 'DAILY');
  }

  function getDailyWeeklyHeader(dailyOrWeekly: string) {
    return dailyOrWeekly === 'DAILY' ? 'Daily %' : 'Weekly %';
  }

  const lists = useRecoilValue<string[]>(listsAtom);

  const industries = useRecoilValue(industriesOptionsAtom);
  const countries = useRecoilValue(countriesOptionsAtom);

  const [filterCountries, setFilterCountries] = useRecoilState(filterCountriesAtom);
  const [filterIndustries, setFilterIndustries] = useRecoilState(filterIndustriesAtom);
  const [filterLists, setFilterLists] = useRecoilState(filterListsAtom);

  return (
    <div>
      {/* when header is on an xs device according to Tailwind, move the titles to a separate line from the sorting */}
      <div className="inline sm:hidden flex justify-end text-xs pb-2 mb-2 font-bold uppercase">
        <div className="flex justify-center">
          <div className="min-w-[3rem]">Score</div>
          <div className="min-w-[3rem]">
            <div onClick={() => toggleDaily()}>{getDailyWeeklyHeader(dailyOrWeekly)}</div>
          </div>
          <div className="min-w-[3.5rem] text-right">Signal</div>
        </div>
      </div>
      <div className="flex justify-between text-xs border-b border-ow-light-grey pb-2 mb-2">
        <div className="font-bold uppercase">
          <div className="flex">
            <TableHeaderSortFilter
              header="Company name"
              selectLabel="Select regions"
              sortField="NAME"
              options={countries}
              selectedOptions={convertToValueLabel(filterCountries)}
              onSelectionChange={setFilterCountries}
              onSortChange={setSort}
              clearAllSetter={setFilterCountries}
            />
          </div>
        </div>
        <div className="flex">
          <div className="hidden lg:inline">
            <div className="w-[300px] mr-2">
              <div className="flex justify-left font-bold uppercase">
                <TableHeaderSortFilter
                  header="Industry"
                  selectLabel="Select industries"
                  sortField="INDUSTRY"
                  options={industries}
                  selectedOptions={convertToValueLabel(filterIndustries)}
                  onSelectionChange={setFilterIndustries}
                  onSortChange={setSort}
                  clearAllSetter={setFilterIndustries}
                />
              </div>
            </div>
          </div>
          {!hideList && (
            <div className="hidden xl:inline mr-2">
              <div className="w-[300px]">
                <div className="flex justify-left font-bold uppercase">
                  <TableHeaderSortFilter
                    header="Add / remove list"
                    selectLabel="Select lists"
                    sortField="USER_LIST"
                    options={lists}
                    selectedOptions={convertToValueLabel(filterLists)}
                    onSelectionChange={setFilterLists}
                    onSortChange={setSort}
                    clearAllSetter={setFilterLists}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="min-w-[3rem] sm:min-w-[5rem]">
            <div className="flex justify-center items-center h-full font-bold uppercase">
              <div className="hidden sm:inline">Score</div>
              <div onClick={() => setSort('SCORE', 'DOWN', true)} className="cursor-pointer">
                {sortField === 'SCORE' && sortDirection === 'UP' && <img src="/icons/up-arrow.png" alt="Up" className="ml-1 h-4 w-4" />}
                {sortField === 'SCORE' && sortDirection === 'DOWN' && (
                  <img src="/icons/down-arrow.png" alt="Down" className="ml-1 h-4 w-4" />
                )}
                {sortField !== 'SCORE' && <img src="/icons/no-sort.png" alt="No sort" className="ml-1 h-4 w-4" />}
              </div>
            </div>
          </div>
          <div className="min-w-[3rem] sm:min-w-[7rem]">
            <div className="flex justify-center items-center h-full font-bold uppercase cursor-pointer">
              <div className="hidden sm:inline">
                <div onClick={() => toggleDaily()}>{getDailyWeeklyHeader(dailyOrWeekly)}</div>
              </div>
              <div onClick={() => setSort('PERCENT_CHANGE', 'DOWN', true)} className="cursor-pointer">
                {sortField === 'PERCENT_CHANGE' && sortDirection === 'UP' && (
                  <img src="/icons/up-arrow.png" alt="Up" className="ml-1 h-4 w-4" />
                )}
                {sortField === 'PERCENT_CHANGE' && sortDirection === 'DOWN' && (
                  <img src="/icons/down-arrow.png" alt="Down" className="ml-1 h-4 w-4" />
                )}
                {sortField !== 'PERCENT_CHANGE' && <img src="/icons/no-sort.png" alt="No sort" className="ml-1 h-4 w-4" />}
              </div>
            </div>
          </div>
          <div className="min-w-[2rem] sm:min-w-[8.5rem] text-left">
            <div className="flex items-center h-full font-bold uppercase">
              <div className="hidden sm:inline">Signal</div>
              <div className="ml-2">
                <NavLink to={`/about`} className="flex justify-end ">
                  <img src="/icons/info.svg" alt="Info" className="mr-1 h-4 w-4 justify-right" />
                </NavLink>
              </div>
              <div onClick={() => setSort('DOWNGRADE', 'DOWN', true)} className="cursor-pointer">
                {sortField === 'DOWNGRADE' && sortDirection === 'UP' && <img src="/icons/up-arrow.png" alt="Up" className="ml-1 h-4 w-4" />}
                {sortField === 'DOWNGRADE' && sortDirection === 'DOWN' && (
                  <img src="/icons/down-arrow.png" alt="Down" className="ml-1 h-4 w-4" />
                )}
                {sortField !== 'DOWNGRADE' && <img src="/icons/no-sort.png" alt="No sort" className="ml-1 h-4 w-4" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
