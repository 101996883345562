import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import Container from '../components/Container';
import useCurrentPage from '../hooks/use-set-current-page';
import Companies from '../components/Companies';
import { userListStatsAtom } from '../store/portfolio';
import { IListStatistics } from '../types/company';
import PageHeader from '../components/PageHeader';
import { getPercentDisplay } from '../utils/format-string';
import { getDowngradeBucket } from '../utils/downgrade-utilities';
import { headerInfoAtom } from '../store/header-info';

export default function List() {
  const currentPage = useCurrentPage();
  const { myList } = useParams();

  const userListStats = useRecoilValue<IListStatistics[]>(userListStatsAtom);
  const [headerInfo, setHeaderInfo] = useRecoilState(headerInfoAtom);

  currentPage.setCurrentPage(`List - ${myList}`);

  useEffect(() => {
    if (userListStats.length === 0) {
      setHeaderInfo(undefined);
      return;
    }

    const findUserListStat = userListStats.find((u) => u.listName === myList);
    if (findUserListStat) {
      setHeaderInfo({
        name: findUserListStat.listName,
        score: findUserListStat.current_score,
        change: getPercentDisplay(findUserListStat.previous_week_percent_change),
        signal: getDowngradeBucket(findUserListStat.current_score / 100.0),
        numCompanies: findUserListStat.numberOfCompanies,
      });
    }
  }, [userListStats]);

  useEffect(() => {
    // On unmount
    return () => {
      setHeaderInfo(undefined);
    };
  }, []);

  return (
    <div className="mb-auto lg:bg-ow-lighter-grey">
      <PageHeader headerInfo={headerInfo} isList={true} />
      <Container>
        <div className="lg:flex justify-left lg:w-full">
          <div className="lg:w-full lg:bg-white lg:p-8">
            <Companies defaultList={[myList ?? '']} />
          </div>
        </div>
      </Container>
    </div>
  );
}
