/*
Highcharts to extend basic functionality.
*/
import Highcharts from 'highcharts';

/*
  Wrapping the reset handler for catching the pointer reset event.
  Purpose is to avoid a bubble hide() call whenever mouse is moving out
  of highcharts chart plot area.
*/
(function (H) {
    H.wrap(H.Pointer.prototype, 'reset', function (proceed, allowMove, delay) {
      if(this.chart.options.tooltip.remainOnMouseout()) {
        // do nothing
      } else {
        proceed.apply(this, Array.prototype.slice.call(arguments, 1));
      }
    });

}(Highcharts));
  