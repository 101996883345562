export const breakPoints = [0.01, 17, 25, 31, 40];
const colorOrder = ['ow-darkbg-grey', 'ow-green', 'ow-light-green', 'ow-orange', 'ow-darkbg-red', 'ow-dark-red'];
// created by looking up colorOrder in tailwind.config   Did not see how to do an automated lookup
export const colorTailwindOrder = ['#A7A7A7', '#00AC41', '#0BD858', '#FF8C00', '#FF5855', '#E12B2B'];
export const scoreBucketName = ['Low articles', 'Very low', 'Low', 'Moderate', 'High', 'Very high'];

export const downgradeProbabilityConstants = [
  {
    text: scoreBucketName[0],
    key: 'Low article volume. Limited data points for scoring in the last 6 months',
    color: colorOrder[0],
    textColor: 'black',
    range: {
      low: 0,
      high: breakPoints[0] / 100.0,
    },
    sortOrder: 7,
  },
  {
    text: scoreBucketName[1],
    key: 'Very Low indicators in news data for upcoming risk events',
    color: colorOrder[1],
    textColor: 'black',
    range: {
      low: breakPoints[0] / 100.0,
      high: breakPoints[1] / 100.0,
    },
    sortOrder: 6,
  },
  {
    text: scoreBucketName[2],
    key: 'Low indicators in news data for upcoming risk events',
    color: colorOrder[2],
    textColor: 'black',
    range: {
      low: breakPoints[1] / 100.0,
      high: breakPoints[2] / 100.0,
    },
    sortOrder: 5,
  },
  {
    text: scoreBucketName[3],
    key: 'Moderate indicators in news data for upcoming risk events',
    color: colorOrder[3],
    textColor: 'black',
    range: {
      low: breakPoints[2] / 100.0,
      high: breakPoints[3] / 100.0,
    },
    sortOrder: 3,
  },
  {
    text: scoreBucketName[4],
    key: 'High indicators in news data for upcoming risk events',
    color: colorOrder[4],
    textColor: 'black',
    range: {
      low: breakPoints[3] / 100.0,
      high: breakPoints[4] / 100.0,
    },
    sortOrder: 2,
  },
  {
    text: scoreBucketName[5],
    key: 'Very High indicators in news data for upcoming risk events',
    color: colorOrder[5],
    textColor: 'black',
    range: {
      low: breakPoints[4] / 100.0,
      high: 1,
    },
    sortOrder: 1,
  },
];
