import { Chart, TooltipFormatterCallbackFunction, TooltipFormatterContextObject } from 'highcharts';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const generateTooltipId = (chartId: number) => `highcharts-custom-tooltip-${chartId}`;

interface Props {
  chart: Chart | null;
  children(formatterContext: TooltipFormatterContextObject): JSX.Element;
}

export function Tooltip({ chart, children }: Props) {
  const [context, setContext] = useState<TooltipFormatterContextObject | null>(null);

  useEffect(() => {
    if (chart) {
      const formatter: TooltipFormatterCallbackFunction = function () {
        setContext(this);

        return `<div id="${generateTooltipId(chart.index)}"></div>`;
      };
      if (chart.options) {
        // filter updates of exported instance that have no tooltip and no options object
        chart.update({
          tooltip: {
            formatter,
            useHTML: true,
          },
        });
      }
    }
  }, [chart]);

  const node = chart && document.getElementById(generateTooltipId(chart.index));

  return node && context ? ReactDOM.createPortal(children(context), node) : null;
}
