import SelectInput from './SelectInput';

export default function SearchListHeader({
  sortOptions,
  selectedSortOption,
  setSelectedSortOption,
  bulkMode,
}: Readonly<{
  sortOptions: any[];
  selectedSortOption: any;
  setSelectedSortOption: any;
  bulkMode: boolean;
}>) {
  const handleSortOptionChange = (option: any) => {
    setSelectedSortOption(option);
  };

  return (
    <div className="flex justify-between text-xs border-b border-ow-light-grey pb-2 mb-2">
      <div className="flex">
        <div className="font-bold uppercase pt-2 pr-10">Name</div>
        <div className="flex">
          <div className="hidden sm:inline pt-2 font-bold">Sort by: &nbsp;</div>
          <div className="pt-0.2 text-xs">
            <SelectInput
              options={sortOptions}
              value={selectedSortOption}
              onChange={handleSortOptionChange}
              minWidth={100}
              textAlign="center"
              color="#2C6EF2"
              bgcolor="#FFFFFF"
              padding={0}
              height={18}
              marginTop={7}
            />
          </div>
        </div>
      </div>
      {!bulkMode && <div className="font-bold uppercase pt-2">Add / Remove from Portfolio</div>}
      {bulkMode && <div className="font-bold uppercase pt-2">Add to selected row</div>}
    </div>
  );
}
