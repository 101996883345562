import { useEffect, useState } from 'react';
import Container from '../components/Container';
import PageHeading from '../components/PageHeadings';
import useCurrentPage from '../hooks/use-set-current-page';
import SearchComponent from '../components/SearchComponent';
import BulkLoaderGrid from '../components/BulkLoaderGrid';
import { toast } from 'react-toastify';

/*
OBJECTIVE

When the user sends us requested companies, but the requested company names do not closely match the names we have in our system.

This screen is a tool for a super user to find the best fit companies using his/her knowledge after the heuristic does its best top 3 guesses.   The goal is
for most companies the first guess will be correct.

HUERISTIC

See the unit_test.csx file for a description of the different code paths and the logic used.  

But at a high level, the hueristic considers companies with the most articles as the  most likely choice.   
Searching for Apple brings Apple, Inc from the US to the top of the list as it has the most company articles.

Words like Corporation, INC, PLC are ignored in the matching as well as punctuation as well as accent marks.   If three words do not match, tries two words and then one word.

See the unit_test.csv in the front_end repo for details on cases considered.
See the regrestion_test.csv for a longer test.

I have found that perhaps 80% of the companies, the first choice is the best one available.
Possible improvement: Include the country in the search.  If we know the answer should be in the US for example.



HOW TO USE
0 Go to: https://fss-lc.owex.oliverwyman.com/bulkLoader
1 Press the Import button and read in the unit_test.csv found in the frontend repo.  The email and user_company column are currently considered.
3 Press the Score button to determine the best match for each company.   May take a few minutes.
4 Then select the drop down if the proposed solution is not the best.
5  Sometimes the right answer will not be one of the three possible answers.  For example, Federal Express is actually FedEx Corp.  There will be a way to filter for FedEx and apply that company code.
6 Some of the proposed answers will be wrong.   Select “Delete me” from the drop down menu such as University of Durham.
7 Press the upload button to:
    a	Adds people if the emails are new
    b	Adds (but never deletes) to a person’s portfolio.   If the upload is run a second time and the user already has that company in his/her portfolio, it will not be added a second time.
8 Press the export button to see the results download to csv.


*/

export default function BulkLoader() {
  const currentPage = useCurrentPage();

  useEffect(() => {
    currentPage.setCurrentPage('BulkLoader');
  }, []);

  const [localSearchTerm, setLocalSearchTerm] = useState<string>('');
  const [inputSearchTerm, setInputSearchTerm] = useState<string>('');

  const handleChangeEvent = (event: any) => {
    if (event.key === 'Enter') {
      handleOnSearchClickEvent(event);
    }
  };

  const handleOnSearchClickEvent = (event: any) => {
    event.preventDefault();
    search();
  };

  const search = () => {
    if (!inputSearchTerm || inputSearchTerm.length < 2) {
      toast.error('Search should be at least 2 characters.');
      return;
    }
    setLocalSearchTerm(inputSearchTerm);
  };

  return (
    <Container>
      <div className="lg:flex lg:justify-center lg:w-full">
        <div className="lg:w-full lg:bg-white lg:p-8">
          <PageHeading title="Bulk Loader" />

          {/* <div className="mr-4 flex">
            <div className="w-full lg:w-[150px] ml-4">
              <Button text="Upload" />
            </div>
          </div> */}
          <BulkLoaderGrid />

          <input
            autoComplete="off"
            className={`w-2/3  rounded border border-ow-grey`}
            type="text"
            name="inputBulkSearch"
            value={inputSearchTerm}
            onChange={(e) => {
              setInputSearchTerm(e.target.value);
            }}
            onKeyDown={handleChangeEvent}
          />

          <SearchComponent searchTerm={localSearchTerm ?? ''} bulkMode={true} />
        </div>
      </div>
    </Container>
  );
}
