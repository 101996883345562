export const timeUnits = {
  TWELVE_MONTHS_IN_DAYS: 365,
  SIX_MONTHS_IN_DAYS: 183,
  FIVE_YEARS_IN_DAYS: 1827,
};

export const timePeriods = {
  DAYS: 1,
  WEEKS: 7,
  MONTHS: 30,
  QUARTERS: 90,
  YEARS: 365,
};
