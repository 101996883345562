import { atom } from 'recoil';
import { getTodayYYYYMMDD, getMonthsAgoYYYYMMDD, getWeeksAgoYYYYMMDD } from '../utils/dates';
import { IPageDateRange } from '../types/timerange';

export const dateDisplaySeparator = ' - ';

export const customID = 'CUSTOM';
export const customRange = 'Custom range';
export const latestDate = getTodayYYYYMMDD();
export const earliestAllowedCustomDate = getMonthsAgoYYYYMMDD(60, latestDate);

export const pageDateRangeOptions: IPageDateRange[] = [
  { value: '5y', label: 'Recent 5 years', earliestDate: getMonthsAgoYYYYMMDD(60, latestDate), latestDate: latestDate },
  { value: '12m', label: 'Recent 12 months', earliestDate: getMonthsAgoYYYYMMDD(12, latestDate), latestDate: latestDate },
  { value: '6m', label: 'Recent 6 months', earliestDate: getMonthsAgoYYYYMMDD(6, latestDate), latestDate: latestDate },
  { value: '1w', label: 'Recent 1 week', earliestDate: getWeeksAgoYYYYMMDD(1, latestDate), latestDate: latestDate },
  { value: customID, label: customRange, earliestDate: earliestAllowedCustomDate, latestDate: latestDate },
];

export const pageDateRangeAtom = atom({
  key: 'pageDateRangeAtom',
  default: pageDateRangeOptions[0],
});

export const filterHelpDialogOpenAtom = atom({
  key: 'filterHelpDialogOpenAtom',
  default: false,
});

export const displayCustomRangeDialogAtom = atom({
  key: 'displayCustomRangeDialogAtom',
  default: false,
});

export const articleOverrideDateRangeAtom = atom<string | null>({
  key: 'articleOverrideDateRangeAtom',
  default: '',
});

export const closePopupAtom = atom({
  key: 'closePopupAtom',
  default: false,
});
