import { useRecoilState } from 'recoil';
import { isSortingInfoModalOpenAtom } from '../store/sorting';

export default function FilteringInfoModal() {
  const [isSortingInfoModalOpen, setIsSortingInfoModalOpen] = useRecoilState(isSortingInfoModalOpenAtom);

  return (
    <div>
      {isSortingInfoModalOpen && (
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 overflow-y-scroll z-30 flex justify-center items-start p-4">
          <div className="bg-white p-6 relative w-full lg:max-w-[75%] xl:max-w-[50%]">
            <button className="absolute top-3 right-3 p-3" onClick={() => setIsSortingInfoModalOpen(false)}>
              <img src="/icons/close-blue.svg" alt="Close" />
            </button>
            <h2 className="font-mmc text-2xl pb-4 mb-4 text-ow-primary border-b border-ow-light-grey">Filters</h2>

            <h2 className="font-mmc text-xl mb-2">MOST RECENT ARTICLES</h2>
            <p className="text-xs mb-4">The default view will display the latest articles at the top.</p>

            <h2 className="font-mmc text-xl mb-2">INFLUENTIAL THEMES</h2>
            <p className="text-xs mb-4">This groups the articles under themes and shows the most influential themes at the top</p>

            <h2 className="font-mmc text-xl mb-2">MOST NEGATIVE ARTICLES</h2>
            <p className="text-xs  mb-4">This orders the articles by sentiment, most negative at the top</p>

            <h2 className="font-mmc text-xl mb-2">MOST POSITIVE NEWS</h2>
            <p className="text-xs">This orders the articles by sentiment, most positive at the top</p>
          </div>
        </div>
      )}
    </div>
  );
}
