import { atom } from 'recoil';
import ICompany, { ICompanyScoreHistory } from '../types/company';

export const companyAtom = atom<ICompany | null>({
  key: 'companyAtom',
  default: null,
});

export const companyScoreHistoryAtom = atom<ICompanyScoreHistory[]>({
  key: 'companyScoreHistory',
  default: [],
});
