import { formatYYYYMMDDForLocale } from '../utils/dates';
import { IArticleSummary, IGroupedArticles, IThemeWithArticles, IThemeWithGroupedArticles } from '../types/article';

const removePunctuation = (str: string): string => {
  // Regular expression to match all punctuation characters
  const punctuationRegex = /[.,/#!$%^&*;:{}=\-_`~()?"']/g;

  // Replace punctuation with an empty string
  return str.replace(punctuationRegex, '');
};

// articles arrive in a sorted order including lastest modification date appears first
//  if the current article is exactly same as last article, put in deletedArticles
//  if the current article is similar (source names do not match), put it in the similar article
export const groupArticles = (allArticles: IArticleSummary[]): IGroupedArticles[] => {
  if (!allArticles) {
    return [];
  }

  const groupedArticlesLocal: IGroupedArticles[] = [];
  let lastPublicationDate = '';
  let lastTitle = '';
  let lastSourceName = '';
  for (const article of allArticles) {
    const currentPublicationDate = formatYYYYMMDDForLocale(article.publication_datetime);
    const currentTitle = removePunctuation(article.title.toLowerCase());

    if (lastPublicationDate === currentPublicationDate && lastTitle === currentTitle && lastSourceName === article.source_name) {
      const lastItem: IGroupedArticles | undefined = groupedArticlesLocal.at(-1);
      lastItem?.deletedArticles.push(article);
    } else if (lastPublicationDate === currentPublicationDate && lastTitle === currentTitle) {
      const lastItem: IGroupedArticles | undefined = groupedArticlesLocal.at(-1);
      lastItem?.similarArticles.push(article);
    } else {
      const newArticleSimilar: IGroupedArticles = {
        group_key: article.dowj_article_id,
        mainArticle: article,
        similarArticles: [],
        deletedArticles: [],
      };
      groupedArticlesLocal.push(newArticleSimilar);
    }
    lastPublicationDate = formatYYYYMMDDForLocale(article.publication_datetime);
    lastTitle = removePunctuation(article.title.toLowerCase());
    lastSourceName = article.source_name;
  }

  return groupedArticlesLocal;
};

export const createThemesWithGroupedArticles = (themesWithArticles: IThemeWithArticles[]): IThemeWithGroupedArticles[] => {
  if (!themesWithArticles) {
    return [];
  }

  const themesWithGroupedArticles: IThemeWithGroupedArticles[] = [];
  for (const themeWithArticles of themesWithArticles) {
    themesWithGroupedArticles.push({
      id: themeWithArticles.id,
      theme_name: themeWithArticles.theme_name,
      groupedArticles: groupArticles(themeWithArticles.articles),
    });
  }
  return themesWithGroupedArticles;
};
