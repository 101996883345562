import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Button from '../components/Button';
import Container from '../components/Container';
import FSSError from '../components/FSSError';
import Loading from '../components/Loading';
import SelectInput from '../components/SelectInput';
import ThemeHeader from '../components/ThemeHeader';
import useApiCaller from '../hooks/use-api-caller';
import { IArticleSummary, IGroupedArticles } from '../types/article';
import { groupArticles } from '../utils/group-articles';
import GroupedArticles from '../components/GroupedArticles';

export default function Theme() {
  const [loading, setLoading] = useState(false);
  const { themeId, companyId } = useParams();
  const [searchParams] = useSearchParams();
  const apiCaller = useApiCaller();

  const [page, setPage] = useState(0);
  const [articles, setArticles] = useState<IArticleSummary[]>([]);
  const [groupedArticles, setGroupedArticles] = useState<IGroupedArticles[]>([]);

  const [allDisplayed, setAllDisplayed] = useState(false);
  const [error, setError] = useState(false);

  const companyOptions = [
    { value: companyId, label: searchParams.get('company') },
    { value: 0, label: 'All articles for this theme' },
  ];

  const [selectedCompanyOption, setSelectedCompanyOption] = useState(companyOptions[0]);

  const fetchThemeArticles = (companyCode: string) => {
    setLoading(true);

    apiCaller
      .getThemeArticles(themeId as string, companyCode, page * apiCaller.ARTICLES_PER_PAGE)
      .then((res) => {
        const newArticles = [...articles, ...res.items];
        setArticles(newArticles);
        const groupedArticlesLocal = groupArticles(newArticles);
        setGroupedArticles(groupedArticlesLocal);

        setPage(page + 1);

        if (newArticles.length > 0 && newArticles.length === res.total) {
          setAllDisplayed(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchThemeArticles(selectedCompanyOption.value as string);
  }, [selectedCompanyOption]);

  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const selectColor = vw < 1023 ? '#ffffff' : undefined;

  const handleCompanyChange = (option: any) => {
    // Reset the article page list and the number of pages loaded
    setPage(0);
    setArticles([]);

    setSelectedCompanyOption(option);
  };

  return (
    <div className="mb-auto lg:bg-ow-lighter-grey">
      <div className="lg:container lg:mx-auto lg:mb-6 lg:px-6">
        <div className="lg:flex lg:justify-center lg:w-full">
          <div className="lg:w-full lg:bg-white lg:pt-8 lg:px-2">
            <div>
              <ThemeHeader theme={searchParams.get('theme') ?? ''}>
                <div className="flex text-xs mt-2">
                  <span className="mr-2">Showing results for:</span>
                  <SelectInput value={selectedCompanyOption} options={companyOptions} onChange={handleCompanyChange} color={selectColor} />
                </div>
              </ThemeHeader>
              <Container>
                <div className="articles-list-container">
                  {/* <h1>Full theme sort</h1> */}
                  <GroupedArticles groupedArticles={groupedArticles} isThemeItem={true} />

                  {articles.length > 0 && !allDisplayed && !error && !loading && (
                    <div className="flex justify-center -mb-4">
                      <div className="w-full lg:w-[150px]">
                        <Button text="Load more" onClick={() => fetchThemeArticles(selectedCompanyOption.value as string)} />
                      </div>
                    </div>
                  )}
                  {loading && <Loading />}

                  {articles.length > 0 && allDisplayed && <p className="text-center text-xs">All articles loaded.</p>}
                  {error && <FSSError />}
                </div>

                {!loading && !error && !articles.length && <p className="text-xs text-center">No articles to display</p>}
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
