import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/PortfolioListItem.scss';
import SearchName from './SearchName';
import { bulkSelectedCompanyAtom } from '../store/bulk-selected';
import { useSetRecoilState } from 'recoil';
import usePortfolioManager from '../hooks/use-portfolio-manager';

export default function SearchListItem({
  company_code,
  name,
  country,
  in_portfolio,
  searchTerm,
  bulkMode,
}: Readonly<{
  company_code: string;
  name: string;
  country: string;
  in_portfolio: boolean;
  searchTerm: string;
  bulkMode: boolean;
}>) {
  const [localInPortfolio, setLocalInPortfolio] = useState(in_portfolio);
  const [isHovered, setIsHovered] = useState(false);
  const setBulkSelectedCompany = useSetRecoilState(bulkSelectedCompanyAtom);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const portfolioManager = usePortfolioManager();

  function toggleInPortfolio() {
    const origInPortfolio = localInPortfolio;
    setLocalInPortfolio(!localInPortfolio);

    if (!localInPortfolio) {
      portfolioManager.add(company_code, name).catch(() => setLocalInPortfolio(origInPortfolio));
    } else {
      portfolioManager.remove(company_code, name).catch(() => setLocalInPortfolio(origInPortfolio));
    }
  }

  function getToggleInPortfolioImage() {
    if (localInPortfolio) {
      return isHovered ? '/icons/remove_search_selected.svg' : '/icons/remove_search.svg';
    }
    return isHovered ? '/icons/add_search_selected.svg' : '/icons/add_search.svg';
  }

  function getToggleInPortfolioAltText() {
    if (localInPortfolio) {
      return 'remove';
    }
    return 'add';
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-2 mt-2 gap-1 ">
        <div className="max-w-[50%]">
          <div>
            <div className="mr-4">
              <NavLink to={`/company/${company_code}`}>
                <SearchName name={name} searchTerm={searchTerm} />
              </NavLink>
              <p className="text-xs fss-company-country">{country}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center ">
          <div className="min-w-[3rem] m-0 pb-0 text-right">
            {!bulkMode && (
              <button onClick={() => toggleInPortfolio()} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <img src={getToggleInPortfolioImage()} alt={getToggleInPortfolioAltText()} />
              </button>
            )}
            {bulkMode && (
              <button
                onClick={() => setBulkSelectedCompany(`${company_code} | ${name} | ${country}`)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src="/icons/add_search.svg" alt="Set company" />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="border-b border-ow-light-grey"></div>
    </div>
  );
}
